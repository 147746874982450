import type { PartialDeep } from 'type-fest';
import { http, HttpResponse } from 'msw';
import { SmartRouteWebApiService } from '../../../src/common/constants';
import { config } from '../../../src/configuration/config';
import { generateAssetMock } from '../data/vehicleDataMock';
import { AssetDataResponse } from '../../../src/store/facade/facadeApi';

// eslint-disable-next-line max-len
const URL = `${config.backend.BACKEND_SERVICE}/${SmartRouteWebApiService.serviceName}/${SmartRouteWebApiService.apiVersion.latest}${SmartRouteWebApiService.endpoints.assetData}/:assetId`;

export default http.get(URL, ({ params }) => {
    const data = generateAssetMock();

    const assetId = params.assetId as string;
    data.asset_data!.asset_id = assetId;

    return HttpResponse.json(data);
});

const buildCoords = (lat: number, lng: number) => ({ latitude: lat, longitude: lng });

const getMockedPosition = (id: string) => {
    switch (id) {
        case '0b3df596-da19-4f39-80b4-14a47dab7a24':
            return undefined;
        case '040191ac-d06d-4567-b13a-7b7fd85d9739':
            return buildCoords(38.843, -8.8654);
        case 'ab9200cc-6921-11ea-bc55-0242ac130003':
            return buildCoords(47.6542, 14.76459);
        case 'fb9200cc-6921-11ea-bc55-0242ac130003':
            return buildCoords(47.02824, 15.42806);
        case 'cb9200cc-6921-11ea-bc55-0242ac130003':
            return buildCoords(48.21074, 11.47365);
        case 'db9200cc-6921-11ea-bc55-0242ac130003':
            return buildCoords(48.21843, 11.4819);
        case '010191ac-d06d-4567-b13a-7b7fd85d9739':
            return buildCoords(48.2109, 11.47254);
        case '020191ac-d06d-4567-b13a-7b7fd85d9739':
            return buildCoords(48.17713, 11.60145);
        case 'eb9200cc-6921-11ea-bc55-0242ac130003':
            return buildCoords(48.29108, 11.56959);
        case 'bb9200cc-6921-11ea-bc55-0242ac130003':
            return buildCoords(48.31025, 11.60836);

        // Production assets
        case 'b152d78d-5b6a-40cc-875d-21924c75bb6c':
            return buildCoords(48.15118, 11.47098);
        case '0e4e4621-7a81-4249-82fe-c1386e14ed31':
            return buildCoords(48.27827, 11.78685);
        case '6ba53d0a-e5ad-432c-8a08-4b15f96a7268':
            return buildCoords(48.30575, 11.2076);
        case 'eadf60ce-381d-46b4-8e0e-d8aa1648fd3f':
            return buildCoords(39.48817, -0.62472);
        case 'd55745ee-2d58-497c-b283-5383a984af1f':
            return buildCoords(38.6491, -9.00628);
        case '5ea55c03-e050-4be2-92b7-85c9ed32519a':
            return buildCoords(40.15041, 32.98932);
        case '62ad35db-245a-47d8-ae6c-df92420ef356':
            return buildCoords(59.1685, 17.63099);
        case 'fcb651ac-f104-4b3f-ad75-3217a1372c01':
            return buildCoords(52.9612, 14.44755);
        case '0bded186-7825-4fcf-bbc3-8272681f3675':
            return buildCoords(49.42799, 11.08295);
        case 'a81a744b-d001-44da-919e-b2e6d7ca5a37':
            return buildCoords(48.35705, 11.44192);
        case '1de9d002-73a7-4ee4-806c-2bc83b6ca2cb':
            return buildCoords(48.22075, 11.48638);
        case 'b08e89b9-186e-427d-814e-4f060c1cefe4':
            return buildCoords(48.21024, 11.47253);
        case '077526e7-d345-4f3c-84dc-366001f727b9':
            return buildCoords(48.21092, 11.4692);
        default:
            return undefined;
    }
};

export const buildAssetDataMock = (data: PartialDeep<AssetDataResponse>) => {
    return http.get(URL, async () => {
        return HttpResponse.json(data);
    });
};
