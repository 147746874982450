import { SelectOption } from '@rio-cloud/rio-uikit/Select';
import useEffectOnce from '@rio-cloud/rio-uikit/useEffectOnce';
import isEmpty from 'lodash/fp/isEmpty';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { MultiSelect } from '../../components/MultiSelect/MultiSelect';
import { useAppDispatch } from '../../configuration/setup/hooks';
import { updateChargingOptionsPayment } from '../../store/charging/chargingSlice';
import { PaymentOption } from '../../store/vehicleProfile/vehicleProfileTypes';

const PaymentOptionLabels = [
    {
        id: PaymentOption.APPLE_PAY,
        label: <FormattedMessage id="intl-msg:smartRoute.tour.payment.applePay" />,
        selected: false,
    },
    {
        id: PaymentOption.PAY_PAL,
        label: <FormattedMessage id="intl-msg:smartRoute.tour.payment.payPal" />,
        selected: false,
    },
    {
        id: PaymentOption.CREDIT_CARD,
        label: <FormattedMessage id="intl-msg:smartRoute.tour.payment.creditCard" />,
        selected: false,
    },
    {
        id: PaymentOption.DEBIT_CARD,
        label: <FormattedMessage id="intl-msg:smartRoute.tour.payment.debitCard" />,
        selected: false,
    },
    {
        id: PaymentOption.GOOGLE_PAY,
        label: <FormattedMessage id="intl-msg:smartRoute.tour.payment.googlePay" />,
        selected: false,
    },
    {
        id: PaymentOption.CHARGING_CARD,
        label: <FormattedMessage id="intl-msg:smartRoute.tour.payment.chargingCard" />,
        selected: false,
    },
    {
        id: PaymentOption.PLUG_CHARGE,
        label: <FormattedMessage id="intl-msg:smartRoute.tour.payment.plugAndCharge" />,
        selected: false,
    },
    {
        id: PaymentOption.MAN_MSP,
        label: <FormattedMessage id="intl-msg:smartRoute.tour.payment.MANeMSP" />,
        selected: false,
    },
    {
        id: PaymentOption.OTHER,
        label: <FormattedMessage id="intl-msg:smartRoute.generic.other" />,
        selected: false,
    },
];

export const PaymentOptions = () => {
    const intl = useIntl();
    const dispatch = useAppDispatch();

    const [selectedOptions, setSelectedOptions] = useState<SelectOption[]>([]);
    const [selectedOptionsCounter, setSelectedOptionsCounter] = useState<number>(0);
    const [paymentOptionsLabels, setPaymentOptionsLabels] = useState<SelectOption[]>([]);

    useEffectOnce(() => {
        setPaymentOptionsLabels(PaymentOptionLabels);
    });

    useEffect(() => {
        dispatch(updateChargingOptionsPayment(selectedOptions));
        setSelectedOptionsCounter(paymentOptionsLabels.filter((option) => option.selected === true).length);
    }, [selectedOptions]);

    const handlePaymentOptionsChange = (selectedOption: SelectOption[]) => {
        const selectedId = isEmpty(selectedOptions)
            ? selectedOption[0]
            : selectedOption.filter((x) => !selectedOptions.includes(x))[0];
        const idIndex = paymentOptionsLabels.findIndex((obj) => obj.id === selectedId);
        if (paymentOptionsLabels[idIndex]) {
            paymentOptionsLabels[idIndex].selected = true;
        }
        setSelectedOptions(selectedOption);
    };

    const handleselectedOptionsCounter = () => {
        if (selectedOptionsCounter < 3) {
            return undefined;
        } else {
            return {
                one: `${intl.formatMessage({
                    id: 'intl-msg:smartRoute.tour.payment.singleOption',
                })}`,
                many: `${intl.formatMessage({
                    id: 'intl-msg:smartRoute.tour.payment.multipleOptions',
                })}`,
            };
        }
    };

    return (
        <div className="display-flex align-items-end margin-bottom-10" data-testid="payment-options">
            <MultiSelect
                keyId="payment-multiselect"
                className="width-100pct"
                optionLabels={paymentOptionsLabels}
                onChange={handlePaymentOptionsChange}
                counterMessage={handleselectedOptionsCounter()}
                label={<FormattedMessage id="intl-msg:smartRoute.tour.paymentOptions" />}
                placeholder={<FormattedMessage id="intl-msg:smartRoute.tour.payment.selectPayment" />}
                noItemMessage="No results"
                useFilter
                multiline
            />
        </div>
    );
};
