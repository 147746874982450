import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import add from 'lodash/fp/add';
import flow from 'lodash/fp/flow';
import get from 'lodash/fp/get';
import last from 'lodash/fp/last';
import reverse from 'lodash/fp/reverse';
import sortBy from 'lodash/fp/sortBy';
import without from 'lodash/fp/without';
import { STORE_BASE_PATH } from '../../common/constants';
import { Coords } from '../../common/types';

export enum WaypointType {
    ADDITIONAL_STOP = 'ADDITIONAL_STOP',
    CHARGING_STATION = 'CHARGING_STATION',
    VEHICLE_LOCATION = 'vehicle-location',
}

export interface Address {
    title: string;
    position: Coords;
    type: string;
    resultType: string;
    station_id?: string;
}

export type Waypoint = {
    id: number;
    address?: string;
    coordinates?: Coords;
    type: WaypointType;
    station_id?: string;
    timeAtStopover: string | null;
    cargoAtDeparture: number | null;
    isInRange: boolean | null;
    extended: boolean;
};

export interface FetchSuggestedAddressesActionPayload {
    id: number;
    addresses: Address[];
}

export interface SearchState {
    waypoints: Waypoint[];
    waypointsOrder: number[];
    suggestedAddresses?: { [key: number]: Address[] };
    fetchSuggestedAddressesError?: string;
}

const initialState: SearchState = {
    waypoints: [
        {
            id: 100,
            address: '',
            type: WaypointType.ADDITIONAL_STOP,
            timeAtStopover: null,
            cargoAtDeparture: null,
            isInRange: null,
            extended: false,
        },
        {
            id: 200,
            address: '',
            type: WaypointType.ADDITIONAL_STOP,
            timeAtStopover: null,
            cargoAtDeparture: null,
            isInRange: null,
            extended: false,
        },
    ],
    waypointsOrder: [100, 200],
    suggestedAddresses: undefined,
};

const getHighestId = flow(sortBy(['id']), last, get('id'), add(1));
const insertAt = (array: number[], index: number, item: any) => {
    const newArray = [...array];
    newArray.splice(index, 0, item);
    return newArray;
};

export const searchSlice = createSlice({
    name: STORE_BASE_PATH + 'search',
    initialState,
    reducers: {
        resetWaypoints: () => initialState,
        waypointChanged: (state: SearchState, action: PayloadAction<Waypoint>) => {
            const waypointToUpdate = state.waypoints.find((waypoint) => waypoint.id === action.payload.id);

            if (!waypointToUpdate) {
                return state;
            }
            state.waypoints = [...without([waypointToUpdate], state.waypoints), action.payload];
        },
        suggestedAddressesChanged: (
            state: SearchState,
            action: PayloadAction<FetchSuggestedAddressesActionPayload>
        ) => {
            const id = action.payload.id;

            if (state.suggestedAddresses === undefined) {
                state.suggestedAddresses = {};
            }

            state.suggestedAddresses = {
                ...state.suggestedAddresses,
                [id]: action.payload.addresses,
            };
        },
        waypointAdded: (state: SearchState, action: PayloadAction<number>) => {
            const { waypoints, waypointsOrder } = state;

            const newId = getHighestId(waypoints);
            const newWaypoint: Waypoint = {
                id: newId,
                address: '',
                type: WaypointType.ADDITIONAL_STOP,
                timeAtStopover: null,
                cargoAtDeparture: null,
                isInRange: null,
                extended: false,
            };
            const position = action.payload !== -1 ? waypointsOrder.indexOf(action.payload) : waypointsOrder.length;
            const newPosition = position + 1;

            state.waypoints = [...waypoints, newWaypoint];
            state.waypointsOrder = insertAt(waypointsOrder, newPosition, newId);
        },
        waypointRemoved: (state: SearchState, action: PayloadAction<number>) => {
            const { waypoints, waypointsOrder } = state;

            if (waypoints.length <= 2) {
                return state;
            }

            state.waypoints = waypoints.filter((waypoint: Waypoint) => waypoint.id !== action.payload);
            state.waypointsOrder = waypointsOrder.filter((waypointId: number) => waypointId !== action.payload);
        },
        waypointsOrderInverted: (state: SearchState) => {
            state.waypointsOrder = reverse([...state.waypointsOrder]);
        },
        waypointsOrderChanged: (
            state: SearchState,
            action: PayloadAction<{ waypoints: Waypoint[]; newIndex: number }>
        ) => {
            const { waypoints, newIndex } = action.payload;
            state.waypoints = waypoints;
            state.waypointsOrder = waypoints.map((waypoint) => waypoint.id);

            // //Remove extended fields if the waypoint is moved to the first or last spot
            if (newIndex === 0 || newIndex === waypoints.length - 1) {
                state.waypoints = state.waypoints.map((item, index) =>
                    index === newIndex ? { ...item, timeAtStopover: null, cargoAtDeparture: null } : item
                );
            }
        },
        updateWaypointRange: (state: SearchState, action: PayloadAction<Waypoint>) => {
            const updatedItems = state.waypoints.map((waypoint) =>
                waypoint.id === action.payload.id ? { ...action.payload } : waypoint
            );
            state.waypoints = updatedItems;
        },
        fetchSuggestedAddressesFailed: (state: SearchState, action: PayloadAction<string>) => {
            state.fetchSuggestedAddressesError = action.payload;
        },
        updateWaypointWaitTime: (state: SearchState, action: PayloadAction<{ waypointId: number; time: string }>) => {
            const { waypointId, time } = action.payload;
            state.waypoints = state.waypoints.map((item) =>
                item.id === waypointId ? { ...item, timeAtStopover: time } : item
            );
        },
        updateWaypointCargo: (state: SearchState, action: PayloadAction<{ waypointId: number; cargo: number }>) => {
            const { waypointId, cargo } = action.payload;
            state.waypoints = state.waypoints.map((item) =>
                item.id === waypointId ? { ...item, cargoAtDeparture: cargo } : item
            );
        },
        updateExtendedWaypoint: (
            state: SearchState,
            action: PayloadAction<{ waypointId: number; extended: boolean }>
        ) => {
            const { waypointId, extended } = action.payload;
            state.waypoints = state.waypoints.map((item) => (item.id === waypointId ? { ...item, extended } : item));
        },
    },
});

export const {
    updateExtendedWaypoint,
    resetWaypoints,
    waypointChanged,
    suggestedAddressesChanged,
    waypointAdded,
    waypointRemoved,
    waypointsOrderInverted,
    waypointsOrderChanged,
    updateWaypointRange,
    fetchSuggestedAddressesFailed,
    updateWaypointWaitTime,
    updateWaypointCargo,
} = searchSlice.actions;

export default searchSlice.reducer;
