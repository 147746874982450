import Fade from '@rio-cloud/rio-uikit/Fade';
import NoData from '@rio-cloud/rio-uikit/components/noData/NoData';
import first from 'lodash/fp/first';
import isNil from 'lodash/fp/isNil';
import isUndefined from 'lodash/fp/isUndefined';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { DEFAULT_ROUTE, MarketplaceSettings } from '../../common/constants';
import { Coordinates, Coords } from '../../common/types';
import { coordinatesToCoords } from '../../common/utils';
import { useAppDispatch, useAppSelector } from '../../configuration/setup/hooks';
import { useGetAssetDataQuery, useGetAssetSubscriptionStateQuery, VehicleType } from '../../store/facade/facadeApi';
import { selectLocale } from '../../store/lang/langSelectors';
import {
    selectOrderedWaypoints,
    selectSuggestedAddresses,
    selectWaypoints,
    selectWaypointsOrder,
} from '../../store/search/searchSelectors';
import {
    suggestedAddressesChanged,
    type Waypoint,
    waypointChanged,
    WaypointType,
} from '../../store/search/searchSlice';
import { selectVehicleProfile } from '../../store/vehicleProfile/vehicleProfileSelectors';
import {
    changeAssetLocked,
    changeCurrentBookingState,
    changeIsEV,
} from '../../store/vehicleProfile/vehicleProfileSlice';
import { selectSelectedAssetId } from '../../store/widget/widgetSelectors';
import { updateAssetId } from '../../store/widget/widgetSlice';

export const VehicleSelection = () => {
    const intl = useIntl();
    const dispatch = useAppDispatch();

    const { pathname } = useLocation();
    const isInSettingsPage = pathname === DEFAULT_ROUTE;

    const userLocale = useAppSelector(selectLocale);
    const waypoints = useAppSelector(selectWaypoints);
    const waypointsOrder = useAppSelector(selectWaypointsOrder);
    const suggestedAddresses = useAppSelector(selectSuggestedAddresses);
    const selectedAssetId = useAppSelector(selectSelectedAssetId);
    const orderedWaypoints = useAppSelector(selectOrderedWaypoints);
    const assetLocked = useAppSelector(selectVehicleProfile).assetLocked;

    const [isBev, setIsBev] = useState<boolean>();
    const [assetName, setAssetName] = useState<string>();
    const [assetType, setAssetType] = useState<VehicleType>();
    const [assetPosition, setAssetPosition] = useState<Coords>();

    const { currentData: vehicleData } = useGetAssetDataQuery(
        { assetId: selectedAssetId! },
        { skip: isNil(selectedAssetId) }
    );

    const { currentData: bookedState } = useGetAssetSubscriptionStateQuery(
        { assetId: assetLocked?.asset_id! },
        { skip: isNil(assetLocked) }
    );

    const clearVehicleLocationFromWaypoint = () => {
        const vehicleLocationFromWaypoint = waypoints.find(
            (waypoint) => waypoint.type === WaypointType.VEHICLE_LOCATION
        );
        const vehicleLocationFromSuggestions = suggestedAddresses?.[waypointsOrder[0]]?.find(
            (suggestion) => suggestion.type === WaypointType.VEHICLE_LOCATION
        );
        if (vehicleLocationFromWaypoint) {
            dispatch(
                waypointChanged({
                    id: vehicleLocationFromWaypoint.id,
                    address: '',
                    type: WaypointType.ADDITIONAL_STOP,
                } as Waypoint)
            );
            dispatch(
                suggestedAddressesChanged({
                    id: vehicleLocationFromWaypoint.id,
                    addresses: [],
                })
            );
        } else if (vehicleLocationFromSuggestions) {
            dispatch(
                suggestedAddressesChanged({
                    id: waypointsOrder[0],
                    addresses: [],
                })
            );
        }
    };

    // TODO - move this to an extra reducer using the fulfilled action
    useEffect(() => {
        if (!isNil(assetLocked) && !isNil(bookedState)) {
            dispatch(changeCurrentBookingState(bookedState.status));
        }
    }, [bookedState]);

    const isNotBooked = !isNil(assetLocked) && bookedState?.status !== 'ACTIVE';

    useEffect(() => {
        if (!isNil(selectedAssetId) && !isNil(vehicleData) && selectedAssetId !== assetLocked?.asset_id) {
            dispatch(changeAssetLocked(vehicleData.asset_data));
        }
    }, [selectedAssetId, vehicleData]);

    useEffect(() => {
        if (!isNil(assetLocked)) {
            const assetCoords = assetLocked?.position && coordinatesToCoords(assetLocked?.position as Coordinates);

            dispatch(changeIsEV(assetLocked?.details?.details_bev ?? true));
            setIsBev(assetLocked?.details?.details_bev);
            setAssetName(assetLocked?.name);
            setAssetType(assetLocked?.vehicleType);
            setAssetPosition(assetCoords);

            if (isNil(assetCoords)) {
                clearVehicleLocationFromWaypoint();
            } else {
                dispatch(
                    suggestedAddressesChanged({
                        id: waypointsOrder[0],
                        addresses: [
                            {
                                type: WaypointType.VEHICLE_LOCATION,
                                title: intl.formatMessage({ id: 'intl-msg:smartRoute.tour.useVehicleLocation' }),
                                resultType: WaypointType.VEHICLE_LOCATION,
                                position: assetCoords,
                            },
                        ],
                    })
                );
            }
        } else {
            clearVehicleLocationFromWaypoint();
        }
    }, [assetLocked]);

    const getAssetIcon = () => {
        switch (assetType) {
            case VehicleType.Truck:
                return 'rioglyph-truck';
            case VehicleType.Bus:
                return 'rioglyph-bus';
            case VehicleType.Van:
                return 'rioglyph-van';
            case VehicleType.Trailer:
                return 'rioglyph-trailer';
            default:
                return 'rioglyph rioglyph-truck-breakdown';
        }
    };

    const getAssetBoxClasses = () => {
        const commonClasses = 'padding-10 padding-left-15 user-select-none rounded border width-100pct';
        const unselectedClasses = 'border-style-dashed bg-lightest border-color-gray text-color-gray';
        const selectedClasses = 'border-color-highlight text-color-highlight bg-lightest';

        if (isUndefined(assetLocked)) {
            return `${commonClasses} ${unselectedClasses}`;
        }
        return `${commonClasses} ${selectedClasses}`;
    };

    const handleClearAssetButton = () => {
        dispatch(updateAssetId(undefined));
        dispatch(changeAssetLocked(undefined));
        setAssetName(undefined);
        setAssetType(undefined);
        setAssetPosition(undefined);
        dispatch(changeCurrentBookingState(undefined));
        const firstWaypoint = first(orderedWaypoints);
        if (!isNil(firstWaypoint) && firstWaypoint.type === WaypointType.VEHICLE_LOCATION) {
            dispatch(waypointChanged({ ...firstWaypoint, address: '' }));
        }
    };

    const UPSELLING_URL = [
        MarketplaceSettings.BUY_BUTTON_URL,
        `?productId=${MarketplaceSettings.productId}`,
        `&level=${MarketplaceSettings.defaultLevel}`,
        `&resourceIds=${selectedAssetId}`,
        `&locale=${userLocale}`,
        `&internalCampaign=${MarketplaceSettings.internalCampaign}`,
        '&noPrice',
        '&noIcon',
        '&buttonLeft',
        '&textOnly',
    ].join('');

    return (
        <div className="display-flex justify-content-between align-items-center padding-top-15">
            <div id="asset-locked" className={getAssetBoxClasses()} data-testid="vehicle-selection-container">
                {!isUndefined(assetLocked) ? (
                    <div
                        className="display-flex justify-content-between align-items-center"
                        data-testid="selected-asset-card"
                    >
                        <Fade animationStyle={Fade.FROM_LEFT} duration={0.9} show={!isUndefined(assetLocked)} initial>
                            <div className="display-flex" key={selectedAssetId} data-testid="vehicle-selected">
                                <div className="margin-right-10">
                                    {isBev ? (
                                        <span className="rioglyph-icon-pair" data-testid="asset-bev-icon">
                                            <span className={`rioglyph ${getAssetIcon()} text-size-16`} />
                                            <span className="rioglyph rioglyph-fuel-electric" />
                                        </span>
                                    ) : (
                                        <span
                                            className={`rioglyph ${getAssetIcon()} text-size-16`}
                                            data-testid="asset-ice-icon"
                                        />
                                    )}
                                </div>
                                <div className="display-flex flex-column">
                                    <span className="text-left" data-testid="asset-name">
                                        {assetName}
                                    </span>
                                    {isNotBooked && (
                                        <div
                                            className="text-size-14 text-color-gray display-flex align-items-baseline"
                                            data-testid="no-data-position"
                                        >
                                            <span className="rioglyph rioglyph-warning-sign margin-right-5" />
                                            <div className="display-flex flex-column">
                                                <span
                                                    className="white-space-nowrap margin-top-1
                                                text-size-12 line-height-14 text-left"
                                                    style={{ letterSpacing: '-0.2px !important' }}
                                                >
                                                    <FormattedMessage id="intl-msg:smartRoute.vehicle.assetNotBooked" />
                                                </span>
                                                <iframe
                                                    title="srp-upselling"
                                                    className="buyButton scale-90"
                                                    src={UPSELLING_URL}
                                                    style={{ height: '18px', transformOrigin: 'left' }}
                                                />
                                            </div>
                                        </div>
                                    )}

                                    {!isNotBooked && isNil(assetPosition) && (
                                        <span
                                            className="display-flex gap-5 align-items-center text-color-gray"
                                            data-testid="no-data-position"
                                        >
                                            <span className="rioglyph rioglyph-warning-sign text-size-14" />
                                            <span
                                                className="white-space-nowrap margin-top-1 text-size-12"
                                                style={{ letterSpacing: '-0.2px !important' }}
                                            >
                                                <FormattedMessage id="intl-msg:smartRoute.vehicle.noPositionData" />
                                            </span>
                                        </span>
                                    )}
                                </div>
                            </div>
                        </Fade>

                        {isInSettingsPage && (
                            <button
                                type="button"
                                className="rioglyph rioglyph-remove-sign clearButtonIcon
                                cursor-pointer text-size-16 margin-left-10 border-none padding-x-5"
                                style={{ backgroundColor: 'inherit' }}
                                onClick={handleClearAssetButton}
                                data-testid="unlock-vehicle"
                            />
                        )}
                    </div>
                ) : (
                    <div
                        className="display-flex justify-content-between align-items-center"
                        data-testid="unselected-asset-card"
                    >
                        <NoData
                            text={intl.formatMessage({
                                id: 'intl-msg:smartRoute.vehicle.noVehicleSelected',
                            })}
                            className="text-color-dark text-transform-none white-space-normal"
                            tooltip={intl.formatMessage({
                                id: 'intl-msg:smartRoute.vehicle.noVehicleSelected.tooltip',
                            })}
                            tooltipPlacement="bottom"
                            data-testid="no-vehicle-selected"
                        />
                    </div>
                )}
            </div>
        </div>
    );
};
