import DatePicker from '@rio-cloud/rio-uikit/DatePicker';
import TimePicker from '@rio-cloud/rio-uikit/TimePicker';
import moment, { Moment } from 'moment';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { getIntlFormaTime, getIntlFormatDate } from '../../common/utils';
import { useAppDispatch, useAppSelector } from '../../configuration/setup/hooks';
import { useEffectOnce } from '../../hooks/hooks';
import { supportedLocaleMap } from '../../store/lang/lang';
import { selectLocale } from '../../store/lang/langSelectors';
import { selectRouteDeparture } from '../../store/route/routeSelectors';
import { updateRouteDeparture } from '../../store/route/routeSlice';

export const RouteDeparture = () => {
    const dispatch = useAppDispatch();

    const userLocale = useAppSelector(selectLocale);
    const routeDeparture = useAppSelector(selectRouteDeparture);

    const localLang = (Object.keys(supportedLocaleMap) as (keyof typeof supportedLocaleMap)[]).find((key) => {
        return key !== userLocale && supportedLocaleMap[key] === userLocale;
    });

    const [departureDate, setDepartureDate] = useState<string>(getIntlFormatDate(localLang, new Date()));
    const [departureTime, setDepartureTime] = useState<string>(getIntlFormaTime(localLang, new Date()));

    useEffectOnce(() => {
        setDepartureDate(routeDeparture.departureDate);
        setDepartureTime(routeDeparture.departureTime);
    });

    const handleChangeDepDate = (currentValue: Moment) => {
        setDepartureDate(getIntlFormatDate(localLang, currentValue.toDate()));
    };

    const handleChangeDepTime = (currentValue: string) => {
        setDepartureTime(currentValue);
    };

    const handleUseCurrentDateTime = () => {
        setDepartureDate(getIntlFormatDate(localLang, new Date()));
        setDepartureTime(getIntlFormaTime(localLang, new Date()));
    };

    const handleDateValidation = (currentDate: Moment) => {
        return Date.parse(new Date().toDateString()) < Date.parse(moment(currentDate).toDate().toDateString());
    };

    useEffect(() => {
        dispatch(updateRouteDeparture({ departureDate, departureTime }));
    }, [departureDate, departureTime]);

    return (
        <div className="align-items-end" data-testid="route-departure">
            <div className="display-flex align-items-end">
                <div className="flex-1-1-0 margin-right-5">
                    <label className="control-label">
                        <FormattedMessage id="intl-msg:smartRoute.generic.date" />
                    </label>
                    <div data-testid="departure-date-picker">
                        <DatePicker
                            onChange={handleChangeDepDate}
                            value={departureDate}
                            timeFormat={false}
                            locale={userLocale}
                            isValidDate={handleDateValidation}
                        />
                    </div>
                </div>
                <div className="flex-1-1-0 margin-right-5 margin-bottom-15">
                    <label className="control-label">
                        <FormattedMessage id="intl-msg:smartRoute.generic.time" />
                    </label>
                    <div data-testid="departure-time-picker">
                        <TimePicker onChange={handleChangeDepTime} value={departureTime} alwaysShowMask={false} />
                    </div>
                </div>
            </div>
            <button
                className="btn btn-primary btn-link btn-link-inline text-size-12 margin-bottom-5"
                onClick={handleUseCurrentDateTime}
            >
                <FormattedMessage id="intl-msg:smartRoute.generic.useCurrentDateTime" />
            </button>
        </div>
    );
};
