import round from 'lodash/fp/round';
import { Coordinates, Coords } from './types';
import moment from 'moment';
import isNil from 'lodash/fp/isNil';

export const coordinatesToCoords = (position: Coordinates): Coords => ({
    lat: position.latitude,
    lng: position.longitude,
});

export const coordsToCoordinates = (position: Coords): Coordinates => ({
    latitude: position.lat,
    longitude: position.lng,
});

export const coordinatesToString = (position: Coords): string => `${position.lat}, ${position.lng}`;

export const mapUnits = (value: number | undefined, mapFn: (v: number) => number) =>
    value ? round(mapFn(value)) : undefined;

export const convertMetersIntoKilometers = (meters?: number) => (meters ? round(meters / 1000) : meters);

export const convert24HourToTimeDisplay = (time: string) => {
    if (isNil(time)) {
        return;
    }

    const regex = /^([0-9][0-9]):([0-5][0-9])/g;
    if (!regex.test(time)) {
        return;
    }

    const [hours, minutes] = time.split(':');
    return convertMinutesIntoHHmm(Number(hours) * 60 + Number(minutes));
};

export const convert24hIntoMinutes = (timeValue: string) => {
    const time = moment(`2020-01-01 ${timeValue}`);
    return time.hours() * 60 + time.minutes();
};

export const convertMinutesIntoHHmm = (timeValue: number | undefined) => {
    if (timeValue === undefined) {
        return undefined;
    }

    const hours = Math.floor(timeValue / 60);
    const minutes = round(timeValue % 60);
    const h = String(hours).padStart(2, '0');
    const m = String(minutes).padStart(2, '0');

    return `${h}h ${m}min`;
};

export const convertSecondsIntoHHmm = (timeValue?: number) => {
    if (timeValue === undefined) {
        return undefined;
    }

    const hours = (timeValue - (timeValue % 3600)) / 3600;
    const minutes = ((timeValue - (timeValue % 60)) / 60) % 60;

    let timeDisplay = '';

    if (hours !== 0) {
        timeDisplay = timeDisplay.concat(`${hours}h`, ' ');
    }
    timeDisplay = timeDisplay.concat(`${minutes}min`);

    return timeDisplay;
};

export const isTomorrow = (date: Date) => {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    return tomorrow.toDateString() === date.toDateString();
};

export const isToday = (date: Date) => {
    const today = new Date();
    return (
        today.getFullYear() === date.getFullYear() &&
        today.getMonth() === date.getMonth() &&
        today.getDate() === date.getDate()
    );
};

export const convertISOStringIntoDateHHmm = (ISOString?: string) => {
    if (!ISOString) {
        return undefined;
    }

    const date = new Date(ISOString);

    let convertedDate = '';
    if (isToday(date)) {
        convertedDate = 'Today';
    } else if (isTomorrow(date)) {
        convertedDate = 'Tomorrow';
    } else {
        convertedDate = `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;
    }

    const requestHours = String(date.getUTCHours()).padStart(2, '0');
    const requestMinutes = String(date.getUTCMinutes()).padStart(2, '0');

    return `${convertedDate}, ${requestHours}:${requestMinutes}`;
};

export const convertStringToDateFormat = (stringDate?: string) => {
    if (stringDate === undefined) {
        return null;
    }
    return new Date(stringDate).toLocaleString('en-US', { timeZone: 'UTC' });
};

export const getIntlFormatDate = (locale: string, dateTime: Date) =>
    new Intl.DateTimeFormat(locale, {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    }).format(dateTime);

export const getIntlFormaTime = (locale: string, dateTime: Date) =>
    new Intl.DateTimeFormat(locale, {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    }).format(dateTime);

export const getMergeDateTime = (date: string, time: string) => {
    let currDate = new Date(date);

    if (!(currDate instanceof Date && !isNaN(currDate.getTime()))) {
        currDate = new Date();
        currDate.setFullYear(Number(date.split('/')[2]));
        currDate.setMonth(Number(date.split('/')[1]) - 1); // work arround on setMonth() bug
        currDate.setDate(Number(date.split('/')[0]));
    }
    currDate.setHours(Number(time.split(':')[0]));
    currDate.setMinutes(Number(time.split(':')[1]));
    currDate.setSeconds(0);
    currDate.setMilliseconds(0);
    return currDate.toISOString();
};

export const convertISOToRelativeDate = (locale: string, ISOString?: string) => {
    if (!ISOString) {
        return undefined;
    }

    const formatTimeAgo = (date: number) => {
        const divisions = [
            { amount: 60, name: 'seconds' },
            { amount: 60, name: 'minutes' },
            { amount: 24, name: 'hours' },
            { amount: 7, name: 'days' },
            { amount: 4.34524, name: 'weeks' },
            { amount: 12, name: 'months' },
            { amount: Number.POSITIVE_INFINITY, name: 'years' },
        ];

        const formatter = new Intl.RelativeTimeFormat(undefined, { numeric: 'auto' });
        let duration = (date - new Date().getTime()) / 1000;

        for (const division of divisions) {
            if (Math.abs(duration) < division.amount) {
                return formatter.format(round(duration), division.name as Intl.RelativeTimeFormatUnit);
            }
            duration /= division.amount;
        }
    };

    const formatedTimeAgo = formatTimeAgo(new Date(ISOString).getTime());
    const timeString: string = getIntlFormaTime(locale, new Date(ISOString));
    let dateString: string;
    if (formatedTimeAgo === 'now') {
        dateString = 'Today';
    } else if (formatedTimeAgo === 'tomorrow') {
        dateString = 'Tomorrow';
    } else {
        dateString = new Intl.DateTimeFormat(locale, {
            month: '2-digit',
            day: '2-digit',
        }).format(new Date(ISOString));
    }

    return `${dateString}, ${timeString}`;
};
