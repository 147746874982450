import { SelectOption } from '@rio-cloud/rio-uikit/Select';
import isEmpty from 'lodash/fp/isEmpty';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { MultiSelect } from '../../components/MultiSelect/MultiSelect';
import { useAppDispatch, useAppSelector } from '../../configuration/setup/hooks';
import { useEffectOnce } from '../../hooks/hooks';
import { selectChargingOptionsOperator } from '../../store/charging/chargingSelectors';
import { updateChargingOptionsOperator } from '../../store/charging/chargingSlice';
import { useGetOperatorListQuery } from '../../store/facade/facadeApi';

export const OperatorOptions = () => {
    const intl = useIntl();
    const dispatch = useAppDispatch();

    const selectedChargingOptionsOperator = useAppSelector(selectChargingOptionsOperator);

    const [selectedOptions, setSelectedOptions] = useState<SelectOption[]>([]);
    const [selectedOptionsCounter, setSelectedOptionsCounter] = useState<number>(0);
    const [operatorOptionsLabels, setOperatorOptionsLabels] = useState<SelectOption[]>([]);

    const { data, isError, isLoading } = useGetOperatorListQuery();

    useEffectOnce(() => setSelectedOptions(selectedChargingOptionsOperator));

    useEffect(() => {
        const operatorsListData = [];

        if (isLoading || isError || !data) {
            return;
        }

        data.items.forEach((operator) =>
            operatorsListData.push({
                id: operator.id,
                label: operator.name,
                selected: selectedChargingOptionsOperator.includes(operator.id!),
            })
        );

        setOperatorOptionsLabels(operatorsListData);
    }, [data, isError, isLoading]);

    useEffect(() => {
        dispatch(updateChargingOptionsOperator(selectedOptions));
        setSelectedOptionsCounter(operatorOptionsLabels.filter((option) => option.selected === true).length);
    }, [selectedOptions]);

    const handleOperatorOptionsChange = (selectedOption: SelectOption[]) => {
        const selectedId = isEmpty(selectedOptions)
            ? selectedOption[0]
            : selectedOption.filter((x) => !selectedOptions.includes(x))[0];
        const idIndex = operatorOptionsLabels.findIndex((obj) => obj.id === selectedId);
        if (operatorOptionsLabels[idIndex]) {
            operatorOptionsLabels[idIndex].selected = true;
        }
        setSelectedOptions(selectedOption);
    };

    const handleselectedOptionsCounter = () => {
        if (selectedOptionsCounter < 3) {
            return undefined;
        } else {
            return {
                one: `${intl.formatMessage({
                    id: 'intl-msg:smartRoute.tour.operator.singleOption',
                })}`,
                many: `${intl.formatMessage({
                    id: 'intl-msg:smartRoute.tour.operator.multipleOptions',
                })}`,
            };
        }
    };

    return (
        <div className="align-items-end margin-bottom-10" data-testid="operator-options">
            <MultiSelect
                keyId="operator-multiselect"
                className="width-100pct"
                optionLabels={operatorOptionsLabels}
                onChange={handleOperatorOptionsChange}
                counterMessage={handleselectedOptionsCounter()}
                label={<FormattedMessage id="intl-msg:smartRoute.chargingStation.operator" />}
                placeholder={<FormattedMessage id="intl-msg:smartRoute.chargingStation.selectOperator" />}
                noItemMessage={<FormattedMessage id="intl-msg:smartRoute.generic.noData" />}
                useFilter
                multiline
            />
        </div>
    );
};
