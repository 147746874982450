import { AppNavigationBar } from '@rio-cloud/rio-uikit/components/navigation/AppNavigationBar';
import { FormattedMessage } from 'react-intl';

export const PageNavigationBar = ({ title, navBack }: PageNavigationBarProps) => {
    return (
        <AppNavigationBar
            title={<FormattedMessage id={title} />}
            onNavigateBack={navBack}
            className="text-medium text-size-18"
            data-testid="page-nav-bar"
        />
    );
};

interface PageNavigationBarProps {
    title: string;
    navBack: () => void;
}
