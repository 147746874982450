import isUndefined from 'lodash/fp/isUndefined';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { DEFAULT_VEHICLE_PROFILE } from '../../common/constants';
import { useAppDispatch, useAppSelector } from '../../configuration/setup/hooks';
import {
    useGetVehicleProfileListQuery,
    useLazyGetVehicleProfileDetailsQuery,
    VehicleProfileListItem,
} from '../../store/facade/facadeApi';
import {
    selectDefaultEvProfile,
    selectIsEV,
    selectIsProfileSubmited,
    selectProfileType,
} from '../../store/vehicleProfile/vehicleProfileSelectors';
import {
    changeDefaultEvProfile,
    changeProfileDetails,
    changeVehicleType,
    resetProfileDetails,
} from '../../store/vehicleProfile/vehicleProfileSlice';
import { VehicleType } from '../../store/vehicleProfile/vehicleProfileTypes';
import { commonHeaderClassName } from '../SettingsOptions/SettingsOptions';
import { customVehicleOption, vehicleTypeOptionLabels } from '../VehicleOptions/ProfileSelect';
import { AdditionalRoutingOptions } from './AdditionalRoutingOptions';
import { AxleInputs } from './AxleInputs';
import { EmissionClassSelect } from './EmissionClassSelect';
import { HazardSelect } from './HazardSelect';
import { ProfileSelect } from './ProfileSelect';
import { TruckTypeSelect } from './TruckTypeSelect';
import { VehicleBatterySelect } from './VehicleBatterySelect';
import { VehicleDimensionsInput } from './VehicleDimensionsInput';

export const VehicleOptions = () => {
    const intl = useIntl();
    const dispatch = useAppDispatch();

    useGetVehicleProfileListQuery();
    const { data: profilesList, isError: profilesListError } = useGetVehicleProfileListQuery();
    const [fetchDetails, { data: currentDetails }] = useLazyGetVehicleProfileDetailsQuery();

    const isEV = useAppSelector(selectIsEV).value;
    const vehicleType = useAppSelector(selectProfileType);
    const isProfileSubmited = useAppSelector(selectIsProfileSubmited);
    const defaultEvProfile = useAppSelector(selectDefaultEvProfile);

    const [evOptions, setEvOptions] = useState<any>([]);
    const [iceOptions, setIceOptions] = useState<any>(vehicleTypeOptionLabels);

    const handleProfileChange = (selectedOption: string) => {
        const selectedProfile = selectedOption as VehicleType;
        dispatch(changeVehicleType(selectedProfile));

        if (selectedOption === VehicleType.CUSTOM_EV || selectedOption === VehicleType.CUSTOM_ICE) {
            dispatch(resetProfileDetails());
        } else if (isEV) {
            fetchDetails({ id: selectedProfile });
        }
    };

    useEffect(() => {
        if (profilesListError) {
            dispatch(changeVehicleType(isEV ? VehicleType.CUSTOM_EV : VehicleType.CUSTOM_ICE));
        }
        if (profilesList) {
            const fetchedEvOptions = profilesList.map((profile) => ({
                id: profile.id,
                label: intl.formatMessage({ id: `intl-msg:smartRoute.vehicle.profile.${profile.profileName}` }),
            }));
            setEvOptions([...fetchedEvOptions, customVehicleOption]);

            const profileIndex = profilesList.findIndex(
                (profile: VehicleProfileListItem) => profile.profileName === DEFAULT_VEHICLE_PROFILE
            );
            const defaultProfileId = profilesList[profileIndex].id;
            if (isUndefined(vehicleType) && !isUndefined(defaultProfileId)) {
                dispatch(changeDefaultEvProfile(defaultProfileId));
                dispatch(changeVehicleType(defaultProfileId));
                fetchDetails({ id: defaultProfileId });
            }
        }
    }, [profilesList]);

    useEffect(() => {
        const isCustomProfile = vehicleType === VehicleType.CUSTOM_EV || vehicleType === VehicleType.CUSTOM_ICE;
        const isToResetProfile = !isProfileSubmited && isCustomProfile;

        if (isToResetProfile) {
            dispatch(resetProfileDetails());
        }
        if (!isProfileSubmited && isEV && currentDetails && !isCustomProfile) {
            dispatch(changeVehicleType(currentDetails.id));
            dispatch(changeProfileDetails(currentDetails));
        }

        if (isEV && isUndefined(currentDetails) && !isCustomProfile && defaultEvProfile) {
            fetchDetails({ id: defaultEvProfile });
        }

        if (!isEV && !isProfileSubmited) {
            const isIceVehicleType = vehicleTypeOptionLabels
                .map((option) => option.id)
                .includes(vehicleType as VehicleType);
            setIceOptions(vehicleTypeOptionLabels);
            dispatch(resetProfileDetails());
            dispatch(changeVehicleType(isIceVehicleType ? vehicleType : iceOptions[0].id));
        }
    }, [vehicleType, isEV, currentDetails]);

    return (
        <div className={`VehicleOptions ${commonHeaderClassName} margin-bottom-15`} data-testid="vehicle-options">
            <ProfileSelect evOptions={evOptions} iceOptions={iceOptions} handleProfileChange={handleProfileChange} />
            <TruckTypeSelect />
            <VehicleDimensionsInput />
            <AxleInputs />
            {isEV ? <VehicleBatterySelect /> : <EmissionClassSelect />}
            <HazardSelect />
            <AdditionalRoutingOptions />
        </div>
    );
};
