import Tag from '@rio-cloud/rio-uikit/Tag';
import TagList from '@rio-cloud/rio-uikit/TagList';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useAppDispatch, useAppSelector } from '../../configuration/setup/hooks';
import { selectChargingOptionsPlugType } from '../../store/charging/chargingSelectors';
import { updateChargingOptionsPlugType } from '../../store/charging/chargingSlice';

export const PlugTypeOptions = () => {
    const dispatch = useAppDispatch();

    const chargingOptionsPlugType = useAppSelector(selectChargingOptionsPlugType);

    const [isPlugTypeCCS, setIsPlugTypeCCS] = useState<boolean>(chargingOptionsPlugType.css);
    const [isPlugTypeMCS, setIsPlugTypeMCS] = useState<boolean>(chargingOptionsPlugType.mcs);

    useEffect(() => {
        dispatch(
            updateChargingOptionsPlugType({
                css: isPlugTypeCCS,
                mcs: isPlugTypeMCS,
            })
        );
    }, [isPlugTypeCCS, isPlugTypeMCS]);

    return (
        <div className="align-items-end margin-bottom-10" data-testid="plug-type-options">
            <label className="control-label" data-testid="charging-grading">
                <FormattedMessage id="intl-msg:smartRoute.vehicle.vehiclePlugType" />
            </label>
            <TagList inline autoTagWidth>
                <Tag
                    clickable
                    selectable
                    round={false}
                    active={isPlugTypeCCS}
                    onClick={() => setIsPlugTypeCCS((previousState) => !previousState)}
                    data-testid="plug-type-tag"
                >
                    <FormattedMessage id="intl-msg:smartRoute.vehicle.plugTypes.CCS" />
                </Tag>

                <Tag
                    clickable
                    selectable
                    round={false}
                    active={isPlugTypeMCS}
                    onClick={() => setIsPlugTypeMCS((previousState) => !previousState)}
                    data-testid="plug-type-tag"
                >
                    <FormattedMessage id="intl-msg:smartRoute.vehicle.plugTypes.MCS" />
                </Tag>
            </TagList>
        </div>
    );
};
