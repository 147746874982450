import TimePicker from '@rio-cloud/rio-uikit/TimePicker';
import isUndefined from 'lodash/fp/isUndefined';

import { useAppDispatch } from '../../configuration/setup/hooks';
import { updateWaypointCargo, updateWaypointWaitTime, Waypoint } from '../../store/search/searchSlice';
import { LabeledNumberInput } from '../../components/LabeledNumberInput/LabeledNumberInput';
import { Unit } from '../../common/constants';
import { FormattedMessage } from 'react-intl';

type Props = {
    waypoint: Waypoint;
};

export const ExtendedOptions = ({ waypoint }: Props) => {
    const dispatch = useAppDispatch();

    const currentTime = waypoint.timeAtStopover ?? '00:00';
    const handleStopoverTimeChange = (time: string) => {
        dispatch(updateWaypointWaitTime({ waypointId: waypoint.id, time }));
    };

    const cargoAtDeparture = waypoint.cargoAtDeparture ?? undefined;
    const handleCargoAtDeparture = (cargo?: number) => {
        if (isUndefined(cargo)) {
            return;
        }
        dispatch(updateWaypointCargo({ waypointId: waypoint.id, cargo }));
    };

    return (
        <div className="margin-top-10 display-flex flex-column">
            <label>
                <FormattedMessage id="intl-msg:smartRoute.tour.timeAtStopover.label" />
            </label>
            <div className="input-group margin-bottom-5" data-testid="waypoint-timepicker">
                <TimePicker
                    value={currentTime}
                    onChange={handleStopoverTimeChange}
                    alwaysShowMask={false}
                    showIcon
                    className="width-100pct"
                />
            </div>
            <label>
                <FormattedMessage id="intl-msg:smartRoute.tour.cargoAtDeparture.label" />
            </label>
            <LabeledNumberInput
                integerOnly
                id="waypoint-cargo-weight"
                className="flex-1-1-0 margin-bottom-0"
                value={cargoAtDeparture}
                unit={Unit.weight}
                onChange={handleCargoAtDeparture}
                iconLeft="rioglyph-weight"
            />
        </div>
    );
};
