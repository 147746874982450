import { DndContext, MouseSensor, TouchSensor, UniqueIdentifier, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToVerticalAxis, restrictToWindowEdges } from '@dnd-kit/modifiers';
import { SortableContext, arrayMove, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { useAppDispatch, useAppSelector } from '../../configuration/setup/hooks';
import { selectOrderedWaypoints } from '../../store/search/searchSelectors';
import { Waypoint, waypointsOrderChanged } from '../../store/search/searchSlice';
import { WaypointListItem } from './WaypointListItem';

type OnSortEndProps = {
    oldIndex: number;
    newIndex: number;
};

export const SortableWaypointContainer = () => {
    const dispatch = useAppDispatch();

    const orderedWaypoints = useAppSelector(selectOrderedWaypoints);
    const allowRemove = orderedWaypoints.length > 2;
    const totalWaypoints = orderedWaypoints.length - 1;

    const getIndex = (id: UniqueIdentifier) => orderedWaypoints.findIndex((wp) => wp.id === id);

    const sensors = useSensors(
        useSensor(MouseSensor, {
            // Require the mouse to move by 10 pixels before activating.
            // Slight distance prevents sortable logic messing with
            // interactive elements in the handler toolbar component.
            activationConstraint: {
                distance: 10,
            },
        }),
        useSensor(TouchSensor, {
            // Press delay of 250ms, with tolerance of 5px of movement.
            activationConstraint: {
                delay: 250,
                tolerance: 5,
            },
        })
    );

    const dragEndHandler = ({ oldIndex, newIndex }: OnSortEndProps) => {
        const waypoints = arrayMove(orderedWaypoints, oldIndex, newIndex);
        dispatch(waypointsOrderChanged({ waypoints, newIndex }));
    };

    return (
        <div className="position-relative padding-top-15 display-flex align-items-center">
            <div className="flex-1-1">
                <DndContext
                    sensors={sensors}
                    autoScroll={false}
                    modifiers={[restrictToVerticalAxis, restrictToWindowEdges]}
                    onDragEnd={({ active, over }) => {
                        if (over && active.id !== over.id) {
                            dragEndHandler({
                                oldIndex: getIndex(active.id),
                                newIndex: getIndex(over.id),
                            });
                        }
                    }}
                >
                    <SortableContext items={orderedWaypoints} strategy={verticalListSortingStrategy}>
                        <div>
                            {orderedWaypoints.map((waypoint: Waypoint, index: number) => (
                                <WaypointListItem
                                    key={waypoint.id}
                                    orderIndex={index}
                                    value={waypoint}
                                    allowRemove={allowRemove}
                                    totalWaypoints={totalWaypoints}
                                />
                            ))}
                        </div>
                    </SortableContext>
                </DndContext>
            </div>
        </div>
    );
};
