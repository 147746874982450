import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { STORE_BASE_PATH } from '../../common/constants';
import { supportedCurrencies } from '../../common/supportedCurrencies';
import { getIntlFormatDate, getIntlFormaTime } from '../../common/utils';
import { config } from '../../configuration/config';
import { TripDetails } from '../../features/fetchData/mappers/mapRoutes';
import { defaultVehicleProfile } from '../vehicleProfile/defaultVehicleProfile';

interface RouteDeparture {
    departureTime: string;
    departureDate: string;
}

export interface RouteConstraints {
    isAvoidAccessHighway: boolean;
    isAvoidTollRoads: boolean;
    isAvoidFerry: boolean;
    isAvoidTunnel: boolean;
    isAvoidRouteAlternatives: boolean;
}

export interface RouteCosts {
    value: number;
    currency: string;
}

export interface RouteState {
    departure: RouteDeparture;
    constraints: RouteConstraints;
    vehicleCosts: RouteCosts;
    energyCosts: RouteCosts;
    fetchRoutesInProgress?: boolean;
    fetchRoutesError?: string;
    fetchRangeInProgress?: boolean;
    fetchRangeError?: string;
    selectedRoute: number | null;
    suggestedRoutes?: TripDetails;
    triggerRangeFetch: boolean;
    isRoutingActive: boolean;
    alternativesOpen: boolean;
    waypointsExtended: boolean;
}

const initialState: RouteState = {
    departure: {
        departureTime: getIntlFormaTime(config.login.defaultLocale, new Date()),
        departureDate: getIntlFormatDate(config.login.defaultLocale, new Date()),
    },
    constraints: {
        isAvoidAccessHighway: false,
        isAvoidTollRoads: false,
        isAvoidFerry: false,
        isAvoidTunnel: false,
        isAvoidRouteAlternatives: false,
    },
    vehicleCosts: {
        value: defaultVehicleProfile.costPerKm,
        currency: supportedCurrencies[0],
    },
    energyCosts: {
        value: defaultVehicleProfile.costPerKWh,
        currency: supportedCurrencies[0],
    },
    fetchRoutesInProgress: false,
    fetchRangeInProgress: false,
    selectedRoute: 0,
    triggerRangeFetch: false,
    isRoutingActive: false,
    alternativesOpen: false,
    waypointsExtended: false,
};

export const routeSlice = createSlice({
    name: STORE_BASE_PATH + 'route',
    initialState,
    reducers: {
        updateRouteDeparture: (state: RouteState, action: PayloadAction<RouteDeparture>) => {
            state.departure = action.payload;
        },
        updateRouteConstraints: (state: RouteState, action: PayloadAction<RouteConstraints>) => {
            state.constraints = action.payload;
        },
        updateVehicleCosts: (state: RouteState, action: PayloadAction<RouteCosts>) => {
            state.vehicleCosts = action.payload;
        },
        updateEnergyCosts: (state: RouteState, action: PayloadAction<RouteCosts>) => {
            state.energyCosts = action.payload;
        },
        selectedRouteChanged: (state: RouteState, action: PayloadAction<number | null>) => {
            state.selectedRoute = action.payload;
        },
        suggestedRoutesChanged: (state: RouteState, action: PayloadAction<TripDetails>) => {
            state.fetchRoutesInProgress = false;
            state.suggestedRoutes = action.payload;
            state.isRoutingActive = true;
        },
        suggestedRoutesRemoved: (state: RouteState) => {
            state.fetchRoutesInProgress = false;
            state.suggestedRoutes = undefined;
            state.isRoutingActive = false;
        },
        fetchRoutesTriggered: (state: RouteState) => {
            state.fetchRoutesInProgress = true;
        },
        fetchRouteSuggestionsFailed: (state: RouteState, action: PayloadAction<string>) => {
            state.fetchRoutesInProgress = false;
            state.fetchRoutesError = action.payload;
        },
        fetchRangeTriggered: (state: RouteState, action: PayloadAction<boolean>) => {
            state.fetchRangeInProgress = action.payload;
        },
        fetchRangeFailed: (state: RouteState, action: PayloadAction<string>) => {
            state.triggerRangeFetch = false;
            state.fetchRangeInProgress = false;
            state.fetchRangeError = action.payload;
        },
        toggleTriggerRangeFetch: (state: RouteState, action: PayloadAction<boolean>) => {
            state.triggerRangeFetch = action.payload;
        },
        toggleAlternativesOpen: (state: RouteState, action: PayloadAction<boolean>) => {
            state.alternativesOpen = action.payload;
        },
    },
});

export const {
    updateRouteDeparture,
    updateRouteConstraints,
    updateVehicleCosts,
    updateEnergyCosts,
    selectedRouteChanged,
    suggestedRoutesChanged,
    suggestedRoutesRemoved,
    fetchRoutesTriggered,
    fetchRouteSuggestionsFailed,
    fetchRangeTriggered,
    fetchRangeFailed,
    toggleTriggerRangeFetch,
    toggleAlternativesOpen,
} = routeSlice.actions;

export default routeSlice.reducer;
