import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { DEFAULT_ROUTE, STORE_BASE_PATH } from '../../common/constants';

export interface AppState {
    sessionExpiredAcknowledged: boolean;
    pageDisplayed: string;
}

const initialState: AppState = {
    sessionExpiredAcknowledged: false,
    pageDisplayed: DEFAULT_ROUTE,
};

export const appSlice = createSlice({
    name: STORE_BASE_PATH + 'app',
    initialState,
    reducers: {
        hideSessionExpiredDialog: (state) => {
            state.sessionExpiredAcknowledged = true;
        },
        updatePage: (state, action: PayloadAction<string>) => {
            state.pageDisplayed = action.payload;
        },
    },
});

export const { hideSessionExpiredDialog, updatePage } = appSlice.actions;

export default appSlice.reducer;
