import { LabeledNumberInput } from '../../components/LabeledNumberInput/LabeledNumberInput';
import { useAppDispatch, useAppSelector } from '../../configuration/setup/hooks';
import {
    selectTrailerAxlesField,
    selectVehicleAxlesField,
    selectWeightPerAxleField,
    selectWeightPerAxleGroupField,
} from '../../store/vehicleProfile/vehicleProfileSelectors';
import {
    changeTrailerAxles,
    changeVehicleAxles,
    changeWeightPerAxle,
    changeWeightPerAxleGroup,
} from '../../store/vehicleProfile/vehicleProfileSlice';
import { HEREVehicleAttibuteNames, VEHCILE_ATTRIBUTES_UNITS } from '../../store/vehicleProfile/vehicleProfileTypes';

export const AxleInputs = () => {
    const dispatch = useAppDispatch();

    const vehicleAxles = useAppSelector(selectVehicleAxlesField);
    const trailerAxles = useAppSelector(selectTrailerAxlesField);

    const weightPerAxle = useAppSelector(selectWeightPerAxleField);
    const weightPerAxleGroup = useAppSelector(selectWeightPerAxleGroupField);

    return (
        <>
            <div className="display-flex align-items-end gap-10" data-testid="axle-inputs">
                <LabeledNumberInput
                    className="flex-1-1-0"
                    labelId="intl-msg:smartRoute.vehicle.vehicleAxles"
                    unitIcon="axle"
                    value={vehicleAxles.value}
                    integerOnly
                    onChange={(value) => dispatch(changeVehicleAxles(value))}
                    disabled={!vehicleAxles.isEditable}
                />
                <LabeledNumberInput
                    className="flex-1-1-0"
                    labelId="intl-msg:smartRoute.vehicle.trailerAxles"
                    unitIcon="axle"
                    value={trailerAxles.value}
                    integerOnly
                    onChange={(value) => dispatch(changeTrailerAxles(value))}
                    disabled={!trailerAxles.isEditable}
                />
            </div>
            <div className="display-flex align-items-end gap-10" data-testid="vehicle-weight">
                <LabeledNumberInput
                    className="flex-1-1-0"
                    labelId="intl-msg:smartRoute.vehicle.weightPerAxle"
                    value={weightPerAxle.value}
                    unit={VEHCILE_ATTRIBUTES_UNITS[HEREVehicleAttibuteNames.WEIGHT_PER_AXLE]}
                    onChange={(value) => dispatch(changeWeightPerAxle(value))}
                    disabled={!weightPerAxle.isEditable}
                />
                <LabeledNumberInput
                    className="flex-1-1-0"
                    labelId="intl-msg:smartRoute.vehicle.weightPerAxleGroup"
                    value={weightPerAxleGroup.value}
                    unit={VEHCILE_ATTRIBUTES_UNITS[HEREVehicleAttibuteNames.WEIGHT_PER_AXLE_GROUP]}
                    onChange={(value) => dispatch(changeWeightPerAxleGroup(value))}
                    disabled={!weightPerAxleGroup.isEditable}
                />
            </div>
        </>
    );
};
