import { Flags } from 'react-feature-flags';
import { FormattedMessage } from 'react-intl';
import { LabeledNumberInput } from '../../components/LabeledNumberInput/LabeledNumberInput';
import { LabeledSelect } from '../../components/LabeledSelect/LabeledSelect';
import { useAppDispatch, useAppSelector } from '../../configuration/setup/hooks';
import { selectOutdoorTemperature, selectTunnelCategory } from '../../store/vehicleProfile/vehicleProfileSelectors';
import { changeAverageOutdoorTemperature, changeTunnelCategory } from '../../store/vehicleProfile/vehicleProfileSlice';
import {
    HEREVehicleAttibuteNames,
    TunnelCategory,
    VEHCILE_ATTRIBUTES_UNITS,
} from '../../store/vehicleProfile/vehicleProfileTypes';

export const TUNNEL_ALL_OPTION = 'All';
const TunnelCategoryOptions = [
    { id: TUNNEL_ALL_OPTION, label: TUNNEL_ALL_OPTION },
    { id: TunnelCategory.B, label: TunnelCategory.B },
    { id: TunnelCategory.C, label: TunnelCategory.C },
    { id: TunnelCategory.D, label: TunnelCategory.D },
    { id: TunnelCategory.E, label: TunnelCategory.E },
];

export type TunnelCategoryTypes =
    | typeof TUNNEL_ALL_OPTION
    | TunnelCategory.B
    | TunnelCategory.C
    | TunnelCategory.D
    | TunnelCategory.E;

export const AdditionalRoutingOptions = () => {
    const dispatch = useAppDispatch();

    const tunnelCategory = useAppSelector(selectTunnelCategory);
    const outdoorTemperature = useAppSelector(selectOutdoorTemperature);

    return (
        <div className="display-flex justify-content-between gap-10" data-testid="aditional-routing-options">
            <LabeledSelect
                className="flex-1-1-0"
                label={<FormattedMessage id="intl-msg:smartRoute.vehicle.tunnelCategory" />}
                optionLabels={TunnelCategoryOptions}
                selectedOption={tunnelCategory}
                onChange={(value) => dispatch(changeTunnelCategory(value))}
            />
            <Flags authorizedFlags={['vehicleOutdoorTemperature']}>
                <LabeledNumberInput
                    integerOnly
                    className="flex-1-1-0"
                    labelId="intl-msg:smartRoute.vehicle.outdoorTemperature"
                    value={outdoorTemperature}
                    unit={VEHCILE_ATTRIBUTES_UNITS[HEREVehicleAttibuteNames.OUTDOOR_TEMPERATURE]}
                    onChange={(value) => dispatch(changeAverageOutdoorTemperature(value))}
                />
            </Flags>
        </div>
    );
};
