import { FormattedMessage } from 'react-intl';
import { LabeledSelect } from '../../components/LabeledSelect/LabeledSelect';
import { useAppDispatch, useAppSelector } from '../../configuration/setup/hooks';
import { changeHazardClassification } from '../../store/vehicleProfile/vehicleProfileSlice';
import { HazardClass } from '../../store/vehicleProfile/vehicleProfileTypes';
import { selectHazardClassification } from '../../store/vehicleProfile/vehicleProfileSelectors';

const hazardClassOptionLabels = [
    { id: HazardClass.NONE, label: <FormattedMessage id="intl-msg:smartRoute.generic.none" /> },
    {
        id: HazardClass.EXPLOSIVE,
        label: <FormattedMessage id="intl-msg:smartRoute.vehicle.dangerousGoodsClassification.explosive" />,
    },
    {
        id: HazardClass.GAS,
        label: <FormattedMessage id="intl-msg:smartRoute.vehicle.dangerousGoodsClassification.gas" />,
    },
    {
        id: HazardClass.FLAMMABLE,
        label: <FormattedMessage id="intl-msg:smartRoute.vehicle.dangerousGoodsClassification.flammable" />,
    },
    {
        id: HazardClass.COMBUSTIBLE,
        label: <FormattedMessage id="intl-msg:smartRoute.vehicle.dangerousGoodsClassification.combustible" />,
    },
    {
        id: HazardClass.ORGANIC,
        label: <FormattedMessage id="intl-msg:smartRoute.vehicle.dangerousGoodsClassification.organic" />,
    },
    {
        id: HazardClass.POISON,
        label: <FormattedMessage id="intl-msg:smartRoute.vehicle.dangerousGoodsClassification.poison" />,
    },
    {
        id: HazardClass.RADIOACTIVE,
        label: <FormattedMessage id="intl-msg:smartRoute.vehicle.dangerousGoodsClassification.radioactive" />,
    },
    {
        id: HazardClass.CORROSIVE,
        label: <FormattedMessage id="intl-msg:smartRoute.vehicle.dangerousGoodsClassification.corrosive" />,
    },
    {
        id: HazardClass.OTHER,
        label: <FormattedMessage id="intl-msg:smartRoute.vehicle.dangerousGoodsClassification.miscellaneous" />,
    },
    {
        id: HazardClass.INHALATION_HAZARD,
        label: <FormattedMessage id="intl-msg:smartRoute.vehicle.dangerousGoodsClassification.inhalationHazard" />,
    },
    {
        id: HazardClass.HARMFUL_TO_WATER,
        label: <FormattedMessage id="intl-msg:smartRoute.vehicle.dangerousGoodsClassification.harmfulToWater" />,
    },
];

export const HazardSelect = () => {
    const dispatch = useAppDispatch();

    const hazardClass = useAppSelector(selectHazardClassification);

    const handleHazardClassificationChange = (selectedOption: string) => {
        const shippedHazardousGoods = selectedOption as HazardClass;
        dispatch(changeHazardClassification(shippedHazardousGoods));
    };

    return (
        <div className="display-flex align-items-end" data-testid="hazard-select">
            <LabeledSelect
                className="width-100pct"
                label={<FormattedMessage id="intl-msg:smartRoute.vehicle.dangerousGoodsClassification" />}
                optionLabels={hazardClassOptionLabels}
                selectedOption={hazardClass}
                onChange={handleHazardClassificationChange}
            />
        </div>
    );
};
