import { Fragment } from 'react';
import { LabeledNumberInput } from '../../components/LabeledNumberInput/LabeledNumberInput';
import { useAppDispatch, useAppSelector } from '../../configuration/setup/hooks';
import {
    selectCargoWeightField,
    selectEmptyWeightField,
    selectHeightField,
    selectLengthField,
    selectTotalWeightField,
    selectWidthField,
} from '../../store/vehicleProfile/vehicleProfileSelectors';
import {
    changeCargoWeight,
    changeEmptyWeight,
    changeHeight,
    changeLength,
    changeWidth,
} from '../../store/vehicleProfile/vehicleProfileSlice';
import { HEREVehicleAttibuteNames, VEHCILE_ATTRIBUTES_UNITS } from '../../store/vehicleProfile/vehicleProfileTypes';

export const VehicleDimensionsInput = () => {
    const dispatch = useAppDispatch();

    const length = useAppSelector(selectLengthField);
    const height = useAppSelector(selectHeightField);
    const width = useAppSelector(selectWidthField);
    const totalWeight = useAppSelector(selectTotalWeightField);
    const emptyWeight = useAppSelector(selectEmptyWeightField);
    const cargoWeight = useAppSelector(selectCargoWeightField);

    const handleTotalLengthChange = (value?: number) => {
        dispatch(changeLength(value));
    };

    const handleHeightChange = (value?: number) => {
        dispatch(changeHeight(value));
    };

    const handleWidthChange = (value?: number) => {
        dispatch(changeWidth(value));
    };

    const handleEmptyWeightChange = (value?: number) => {
        dispatch(changeEmptyWeight(value));
    };

    const handleCargoWeightChange = (value?: number) => {
        dispatch(changeCargoWeight(value));
    };

    return (
        <Fragment>
            <div className="display-flex align-items-end gap-10" data-testid="vehicle-dimensions">
                <LabeledNumberInput
                    id="input-height"
                    className="flex-1-1-0"
                    labelId="intl-msg:smartRoute.vehicle.height"
                    value={height.value}
                    unit={VEHCILE_ATTRIBUTES_UNITS[HEREVehicleAttibuteNames.HEIGHT]}
                    onChange={handleHeightChange}
                    disabled={!height.isEditable}
                />
                <LabeledNumberInput
                    id="input-width"
                    className="flex-1-1-0"
                    labelId="intl-msg:smartRoute.vehicle.width"
                    value={width.value}
                    unit={VEHCILE_ATTRIBUTES_UNITS[HEREVehicleAttibuteNames.WIDTH]}
                    onChange={handleWidthChange}
                    disabled={!width.isEditable}
                />
            </div>
            <div className="display-flex align-items-end gap-10" data-testid="vehicle-total-dimensions">
                <LabeledNumberInput
                    id="input-total-length"
                    className="flex-1-1-0"
                    labelId="intl-msg:smartRoute.vehicle.totalLength"
                    value={length.value}
                    unit={VEHCILE_ATTRIBUTES_UNITS[HEREVehicleAttibuteNames.LENGTH]}
                    onChange={handleTotalLengthChange}
                    disabled={!length.isEditable}
                />
                <LabeledNumberInput
                    integerOnly
                    id="input-total-weight"
                    className="flex-1-1-0"
                    labelId="intl-msg:smartRoute.vehicle.totalWeight"
                    value={totalWeight.value}
                    unit={VEHCILE_ATTRIBUTES_UNITS[HEREVehicleAttibuteNames.TOTAL_WEIGHT]}
                    disabled={!totalWeight.isEditable}
                />
            </div>
            <div className="display-flex align-items-end gap-10" data-testid="vehicle-cargo-weight">
                <LabeledNumberInput
                    id="input-empty-weight"
                    className="flex-1-1-0"
                    labelId="intl-msg:smartRoute.vehicle.emptyWeight"
                    value={emptyWeight.value}
                    unit={VEHCILE_ATTRIBUTES_UNITS[HEREVehicleAttibuteNames.EMPTY_WEIGHT]}
                    onChange={handleEmptyWeightChange}
                    disabled={!emptyWeight.isEditable}
                />
                <LabeledNumberInput
                    integerOnly
                    id="input-cargo-weight"
                    className="flex-1-1-0"
                    labelId="intl-msg:smartRoute.vehicle.cargoWeight"
                    value={cargoWeight.value}
                    unit={VEHCILE_ATTRIBUTES_UNITS[HEREVehicleAttibuteNames.CARGO_WEIGHT]}
                    onChange={handleCargoWeightChange}
                    disabled={!cargoWeight.isEditable}
                />
            </div>
        </Fragment>
    );
};
