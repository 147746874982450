import { SortableWaypointContainer } from './SortableWaypointContainer';
import { WaypointsOptions } from './WaypointsOptions';

export const SearchEditor = () => {
    return (
        <div className="RoutePlannerSearch" data-testid="RoutePlannerSearch">
            <SortableWaypointContainer />
            <WaypointsOptions />
        </div>
    );
};
