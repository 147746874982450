import gt from 'lodash/fp/gt';
import isNil from 'lodash/fp/isNil';
import isNull from 'lodash/fp/isNull';
import { FormattedMessage } from 'react-intl';
import { convertSecondsIntoHHmm, convertStringToDateFormat, convert24HourToTimeDisplay } from '../../../common/utils';
import { RangeStatusIcon } from '../../../components/RangeStatusIcon/RangeStatusIcon';
import { useAppSelector } from '../../../configuration/setup/hooks';
import { ChargingStationSection } from '../../../store/facade/facadeApi';
import { selectIsWaypointInRange, selectWaypointById } from '../../../store/search/searchSelectors';
import { CurrentStatus } from '../SectionsContainer';
import { Unit } from '../../../common/constants';

type ChargingPanelProps = {
    charging: ChargingStationSection;
    waypointId: number;
} & CurrentStatus;

export const ChargingPanel = ({ charging, waypointId }: ChargingPanelProps) => {
    const isWaypointInRange = useAppSelector((state) => selectIsWaypointInRange(state, waypointId));
    const date = convertStringToDateFormat(charging.estimated_arrival_time);
    const waypoint = useAppSelector((state) => selectWaypointById(state, waypointId));
    const hasTimeAtStopover = !isNil(waypoint?.timeAtStopover) && waypoint?.timeAtStopover !== '00:00';

    return (
        <li className="timeline-element" data-testid="timeline-element">
            <div className="timeline-date margin-right-10">
                <div className="border border-width-3 border-color-white">
                    <span className="badge badge-lg badge-muted padding-5">
                        <span className="rioglyph rioglyph-filling-e-station" />
                    </span>
                </div>
            </div>
            <div className="timeline-content margin-bottom-15">
                <div className="text-medium text-size-16 text-color-darker margin-top-5 margin-bottom-5">
                    <span className="margin-right-5">
                        <FormattedMessage id="intl-msg:smartRoute.tour.chargingStops" />
                    </span>
                    {!isNull(isWaypointInRange) && (
                        <RangeStatusIcon isWaypointInRange={isWaypointInRange!} badgeSize="badge-sm" />
                    )}
                </div>
                <div className="display-flex flex-column gap-2">
                    {gt(charging.duration, 0) && (
                        <div>
                            <span className="rioglyph rioglyph-time text-color-gray margin-right-5" />
                            {!isNil(charging.duration) ? <span>{convertSecondsIntoHHmm(charging.duration)}</span> : '-'}
                        </div>
                    )}

                    <div>
                        <span className="rioglyph rioglyph-calendar text-color-gray margin-right-5" />
                        <span>{date}</span>
                    </div>

                    <div>
                        <span className="rioglyph rioglyph-addressbook text-color-gray margin-right-5" />
                        <span>{charging.charging_station.operator.name}</span>
                    </div>

                    <div>
                        <span className="rioglyph rioglyph-map-marker text-color-gray margin-right-5" />
                        <span>{charging.charging_station.address}</span>
                    </div>
                    {waypoint?.cargoAtDeparture && (
                        <div>
                            <span className="rioglyph rioglyph-weight text-color-gray margin-right-5" />
                            <span>{waypoint?.cargoAtDeparture}</span>
                            <span>&nbsp;{Unit.weight}</span>
                        </div>
                    )}

                    {hasTimeAtStopover && (
                        <div>
                            <span className="rioglyph rioglyph-time-alt text-color-gray margin-right-5" />
                            <span>{convert24HourToTimeDisplay(waypoint.timeAtStopover!)}</span>
                        </div>
                    )}
                </div>
            </div>
        </li>
    );
};
