import Multiselect, { MultiselectCounterMessage, MultiselectOption } from '@rio-cloud/rio-uikit/Multiselect';
import { ReactElement } from 'react';

export const MultiSelect = (props: MultiSelectProps) => {
    const {
        keyId,
        className,
        counterMessage,
        label,
        optionLabels,
        placeholder,
        multiline,
        useFilter,
        noItemMessage,
        onChange = () => {},
    } = props;

    return (
        <div className={className} data-testid="multiselect">
            <label className="multiselect-label">{label}</label>
            <div className="width-100pct">
                <Multiselect
                    id={keyId}
                    options={optionLabels}
                    onChange={onChange}
                    placeholder={placeholder}
                    counterMessage={counterMessage}
                    noItemMessage={noItemMessage}
                    useFilter={useFilter}
                    multiline={multiline}
                />
            </div>
        </div>
    );
};

interface MultiSelectProps {
    keyId: string;
    className?: string;
    counterMessage?: MultiselectCounterMessage;
    label?: ReactElement;
    optionLabels: MultiselectOption[];
    placeholder?: ReactElement;
    onChange?: (MultiselectOption: string) => void;
    noItemMessage?: string | ReactElement;
    useFilter?: boolean;
    multiline?: boolean;
}
