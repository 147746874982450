import ListMenu from '@rio-cloud/rio-uikit/ListMenu';
import { ListMenuItem } from '@rio-cloud/rio-uikit/components/listMenu/ListMenuGroup';
import { MouseEventHandler } from 'react';

import { useAppDispatch, useAppSelector } from '../../configuration/setup/hooks';
import { store } from '../../configuration/setup/store';
import { RouteDetails } from '../../features/fetchData/mappers/mapRoutes';
import {
    createRouteInfo,
    propagateRoute,
    propagateRoutes,
} from '../../features/livemonitorCommunication/propagateRoutesToParent';
import { selectedRouteChanged, toggleAlternativesOpen } from '../../store/route/routeSlice';
import ExpanderPanel from '@rio-cloud/rio-uikit/ExpanderPanel';
import { selectSelectedRoute } from '../../store/route/routeSelectors';

interface TourAlternativesProps {
    routes: RouteDetails[];
}

interface MenuItemsProps {
    selectedRoute: number;
    onItemClick: MouseEventHandler<HTMLSpanElement>;
    onItemHover: MouseEventHandler<HTMLSpanElement>;
    onItemHoverLeave: MouseEventHandler<HTMLSpanElement>;
    routes: RouteDetails[];
}

const menuItems = ({
    selectedRoute,
    onItemClick,
    onItemHover,
    onItemHoverLeave,
    routes,
}: MenuItemsProps): ListMenuItem[] => {
    const listMenu: ListMenuItem[] = [];
    listMenu.push({ navItems: [] });

    routes.forEach((route, index) => {
        const routeMarkerLabel = createRouteInfo(route.summary!, store.getState());
        listMenu[0].navItems.push({
            key: route.id,
            item: (
                <span
                    data-key={index.toString()}
                    data-testid="menu-list-item"
                    className={selectedRoute === index ? 'text-medium active' : ''}
                    onClick={onItemClick}
                    onMouseOver={onItemHover}
                    onMouseOut={onItemHoverLeave}
                >
                    <span className="rioglyph rioglyph-route padding-right-10" aria-hidden="true" />
                    {routeMarkerLabel}
                </span>
            ),
        });
    });

    return listMenu;
};

export const TourAlternatives = ({ routes }: TourAlternativesProps) => {
    const dispatch = useAppDispatch();
    const selectedRoute = useAppSelector(selectSelectedRoute) ?? 0;

    const currentRoute = routes.at(selectedRoute);
    const currentLabel = createRouteInfo(currentRoute?.summary!, store.getState());

    const onItemClick = (event: MouseEvent) => {
        const keyString = (event.target as HTMLSpanElement).getAttribute('data-key') as string;
        const key = Number(keyString);
        dispatch(selectedRouteChanged(key));
        dispatch(propagateRoutes(key));
    };

    const onToggleExpander = (isOpen: boolean) => {
        dispatch(toggleAlternativesOpen(isOpen));
        if (isOpen) {
            dispatch(propagateRoutes());
        } else {
            dispatch(propagateRoute(selectedRoute));
        }
    };

    const onItemHover = (event: MouseEvent) => {
        const keyString = (event.target as HTMLSpanElement).getAttribute('data-key') as string;
        const key = Number(keyString);
        dispatch(propagateRoutes(key));
    };

    const onItemHoverLeave = (event: MouseEvent) => {
        dispatch(propagateRoutes(selectedRoute));
    };

    return (
        <div data-testid="tour-alternatives">
            <div className="list-group" data-testid="tour-alternatives-panel">
                <ExpanderPanel
                    title={
                        <div className="display-flex align-items-center">
                            <span className="rioglyph rioglyph-menu-hamburger margin-right-10" />
                            <span>{currentLabel}</span>
                        </div>
                    }
                    bsStyle="default"
                    onToggle={onToggleExpander}
                    unmountOnExit
                    className="shadow-default"
                    data-testid="expander-panel"
                >
                    <ListMenu
                        responsive={false}
                        groupClassName="padding-0"
                        menuItems={menuItems({ selectedRoute, onItemClick, onItemHover, onItemHoverLeave, routes })}
                    />
                </ExpanderPanel>
            </div>
        </div>
    );
};
