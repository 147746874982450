import AutoSuggest, { type AutoSuggestSuggestion } from '@rio-cloud/rio-uikit/AutoSuggest';
import isUndefined from 'lodash/fp/isUndefined';
import { type IntlShape, useIntl } from 'react-intl';
import { RangeStatusIcon } from '../../components/RangeStatusIcon/RangeStatusIcon';
import { useAppSelector } from '../../configuration/setup/hooks';
import { selectSuggestedRoutes } from '../../store/route/routeSelectors';
import { selectIsWaypointInRange, selectOrderedWaypoints } from '../../store/search/searchSelectors';
import { type Address, type Waypoint, WaypointType } from '../../store/search/searchSlice';
import { selectVehicleProfile } from '../../store/vehicleProfile/vehicleProfileSelectors';
import { changeBoundingBox } from '../../store/widget/actions/changeBoundingBox';

export type Suggestion = Address & { label: string; type: string; header: boolean };

export interface WaypointInputProps {
    waypoint: Waypoint;
    suggestedAddresses: Address[];
    onInputValueChanged: Function;
    onSuggestionSelected: Function;
    onInputCleared: Function;
    onRemove: () => void;
    allowRemove: boolean;
    icon: string;
}

const mapSuggestionIconType = (addressType: string) => {
    switch (addressType) {
        case 'customer':
            return 'pushpin';
        case 'workshop':
            return 'workshop';
        case 'circle':
        case 'polygon':
            return 'geofence';
        case WaypointType.VEHICLE_LOCATION:
            return 'truck';
        case 'charging-station':
            return 'filling-e-station';
        default:
            return 'map-marker';
    }
};

const mapSuggestionTitle = (addressType: string) => {
    switch (addressType) {
        case 'customer':
            return 'intl-msg:smartRoute.generic.customers';
        case 'workshop':
            return 'intl-msg:smartRoute.generic.workshops';
        case 'circle':
        case 'polygon':
            return 'intl-msg:smartRoute.generic.geofences';
        case WaypointType.VEHICLE_LOCATION:
            return 'intl-msg:smartRoute.generic.vehicle';
        case 'charging-station':
            return 'intl-msg:smartRoute.chargingStation';
        default:
            return 'intl-msg:smartRoute.generic.address';
    }
};

const getFallbackText = (waypoint: Waypoint) => {
    if (waypoint.coordinates) {
        return `${waypoint.coordinates?.lat}, ${waypoint.coordinates?.lng}`;
    }
    return '';
};

export const addSuggestionHeaders = (suggestions: Partial<Suggestion>[], intl: IntlShape): Partial<Suggestion>[] => {
    let previousCategory = '';
    return suggestions.reduce((acc, curr) => {
        const currCategory = mapSuggestionTitle(curr.resultType!);
        if (previousCategory !== currCategory) {
            previousCategory = currCategory;
            acc.push({
                label: intl.formatMessage({ id: currCategory }),
                header: true,
            });
        }
        acc.push(curr);

        return acc;
    }, [] as Partial<Suggestion>[]);
};

export const WaypointInput = (props: WaypointInputProps) => {
    const intl = useIntl();

    const {
        waypoint,
        suggestedAddresses,
        onInputValueChanged,
        onSuggestionSelected,
        onInputCleared,
        onRemove,
        allowRemove,
        icon,
    } = props;

    const trip = useAppSelector(selectSuggestedRoutes);
    const waypointId = waypoint.id;
    const isWaypointInRange = useAppSelector((state) => selectIsWaypointInRange(state, waypointId));
    const orderedWaypoints = useAppSelector(selectOrderedWaypoints);
    const assetLocked = useAppSelector(selectVehicleProfile).assetLocked;
    const isBev = assetLocked?.details?.details_bev!;
    const isFirstWaypoint = waypointId === orderedWaypoints[0].id;

    const handleSuggestionsFetchRequested = (result: { value: string }) => {
        const { value } = result;
        if (waypoint.address !== value) {
            onInputValueChanged(waypoint.id, value);
        }
    };

    const handleSuggestionSelected = (event: any, { suggestion }: any) => {
        onSuggestionSelected(waypoint.id, suggestion);
        if (suggestion.resultType === WaypointType.VEHICLE_LOCATION) {
            changeBoundingBox([suggestion.position]);
        }
    };

    const renderSuggestion = (suggestion: AutoSuggestSuggestion) => {
        return (
            <span>
                <span className={`rioglyph rioglyph-${suggestion.type} margin-right-3`} />
                <span>{suggestion.label}</span>
            </span>
        );
    };

    const suggestions: Partial<Suggestion>[] = suggestedAddresses.map((address) => {
        return {
            ...address,
            label: address.title,
            type: mapSuggestionIconType(address.resultType),
        };
    });

    const suggestionsWithHeaders = addSuggestionHeaders(suggestions, intl) as AutoSuggestSuggestion[];

    const inputProps = {
        onClear: () => {
            onInputCleared(waypoint.id);
        },
        placeholder: intl.formatMessage({ id: 'intl-msg:smartRoute.tour.findAddress' }),
        icon: `rioglyph-${icon}`,
        value: waypoint.address ?? getFallbackText(waypoint),
    };

    const removeAddon = (
        <div className="input-group-addon" onMouseDown={onRemove}>
            <span className="rioglyph rioglyph-trash cursor-pointer text-color-gray hover-text-color-danger" />
        </div>
    );

    const handleTrailingInputAddons = () => (allowRemove ? [removeAddon] : []);

    return (
        <div className="display-flex align-items-center">
            <AutoSuggest
                className="WaypointInput flex-1-0"
                dropdownClassName="max-height-350"
                inputProps={inputProps}
                suggestions={suggestionsWithHeaders}
                onSuggestionsFetchRequested={handleSuggestionsFetchRequested}
                onSuggestionSelected={handleSuggestionSelected}
                renderSuggestion={renderSuggestion}
                trailingInputAddons={handleTrailingInputAddons()}
            />
            {isBev && trip && (
                <div className="width-26 margin-left-5 margin-right--5">
                    {!isFirstWaypoint && !isUndefined(isWaypointInRange) && (
                        <RangeStatusIcon isWaypointInRange={isWaypointInRange} badgeSize="badge-lg" />
                    )}
                </div>
            )}
        </div>
    );
};
