import ExpanderPanel from '@rio-cloud/rio-uikit/ExpanderPanel';
import { FormattedMessage } from 'react-intl';
import { useRouteWidgetContext } from '../../context/RouteWidgetContext';
import {
    commonExpanderClassName,
    commonHeaderClassName,
    commonBodyClassName,
} from '../SettingsOptions/SettingsOptions';
import { GradingOptions } from './GradingOptions';
import { OperatorOptions } from './OperatorOptions';
import { PaymentOptions } from './PaymentOptions';
import { PlugTypeOptions } from './PlugTypeOptions';
import { PowerOptions } from './PowerOptions';
import { SoCOptions } from './SoCOptions';

export const ChargingOptions = () => {
    const widgetContext = useRouteWidgetContext();

    return (
        <div className={`ChargingOptions ${commonHeaderClassName}`} data-testid="charging-options">
            <ExpanderPanel
                bsStyle="default"
                className={commonExpanderClassName}
                bodyClassName={commonBodyClassName}
                title={<FormattedMessage id="intl-msg:glossary.stateOfCharge" />}
                onToggle={(value: boolean) => {
                    widgetContext.setStateOfChargeExpand(value);
                }}
                open={widgetContext.stateOfChargeExpnd}
            >
                <SoCOptions />
            </ExpanderPanel>
            <ExpanderPanel
                bsStyle="default"
                className={commonExpanderClassName}
                bodyClassName={commonBodyClassName}
                title={<FormattedMessage id="intl-msg:smartRoute.generic.options" />}
                onToggle={(value: boolean) => {
                    widgetContext.setCCharginOptionsExpnd(value);
                }}
                open={widgetContext.charginOptionsExpnd}
            >
                <GradingOptions />
                <PlugTypeOptions />
                <PowerOptions />
                <OperatorOptions />
                <PaymentOptions />
            </ExpanderPanel>
        </div>
    );
};
