import Checkbox from '@rio-cloud/rio-uikit/Checkbox';
import { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';

export const CardCheckboxOption = ({
    checked,
    disabled,
    onToggle,
    cardIntlTitle,
    cardTextSubTitle,
    checkboxClasses,
    cardChild,
}: CardCheckboxOptionProps) => {
    return (
        <div className={checkboxClasses} data-testid="srp-card">
            <Checkbox
                checked={checked}
                disabled={disabled ?? false}
                onClick={() => onToggle(!checked)}
                data-testid="srp-card-checkbox"
            >
                <div className="checkbox-text-wrapper display-flex justify-content-between">
                    {cardChild ? (
                        <div className="margin-right-15" data-testid="srp-card-child">
                            {cardChild}
                        </div>
                    ) : (
                        <div className="margin-right-15">
                            <div className="text-normal text-size-14 text-color-darker">
                                <FormattedMessage id={cardIntlTitle} data-testid="srp-card-checkbox-title" />
                            </div>
                            <div className="text-light text-size-12 text-color-dark">{cardTextSubTitle}</div>
                        </div>
                    )}
                </div>
            </Checkbox>
        </div>
    );
};

interface CardCheckboxOptionProps {
    checked: boolean;
    onToggle: (checked: boolean) => void;
    disabled?: boolean;
    cardIntlTitle?: string;
    cardTextSubTitle?: string;
    checkboxClasses?: string;
    cardChild?: ReactElement;
}
