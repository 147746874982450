import isUndefined from 'lodash/fp/isUndefined';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Unit } from '../../common/constants';
import { LabeledNumberInput } from '../../components/LabeledNumberInput/LabeledNumberInput';
import { LabeledSelect } from '../../components/LabeledSelect/LabeledSelect';
import { useAppDispatch, useAppSelector } from '../../configuration/setup/hooks';
import { useGetVehicleProfileDetailsQuery } from '../../store/facade/facadeApi';
import {
    selectBatteryCapacity,
    selectIsEV,
    selectIsProfileSubmited,
    selectProfileType,
} from '../../store/vehicleProfile/vehicleProfileSelectors';
import { changeBatteryCapacity } from '../../store/vehicleProfile/vehicleProfileSlice';
import {
    HEREVehicleAttibuteNames,
    VEHCILE_ATTRIBUTES_UNITS,
    VehicleType,
} from '../../store/vehicleProfile/vehicleProfileTypes';

export const VehicleBatterySelect = () => {
    const [options, setOptions] = useState<any>();

    const dispatch = useAppDispatch();

    const isEV = useAppSelector(selectIsEV).value;
    const isProfileSubmited = useAppSelector(selectIsProfileSubmited);
    const batteryCapacity = useAppSelector(selectBatteryCapacity);
    const selectedProfile = useAppSelector(selectProfileType);
    const { data: currentDetails } = useGetVehicleProfileDetailsQuery(
        { id: selectedProfile as string },
        { skip: isUndefined(selectedProfile) || !isEV || selectedProfile === VehicleType.CUSTOM_EV }
    );

    const intl = useIntl();
    const batteryLabel = intl.formatMessage({ id: 'intl-msg:smartRoute.vehicle.batteryPack' });

    const [isCostumProfile, setIsCostumProfile] = useState<boolean>(
        selectedProfile === VehicleType.CUSTOM_EV || selectedProfile === VehicleType.CUSTOM_ICE
    );

    useEffect(() => {
        setIsCostumProfile(selectedProfile === VehicleType.CUSTOM_EV || selectedProfile === VehicleType.CUSTOM_ICE);
    }, [selectedProfile]);

    useEffect(() => {
        const capacityPerBattery = currentDetails?.batteryCapacityPerPack;
        if (currentDetails && capacityPerBattery && currentDetails.emptyWeightVehicle) {
            const packOptions = currentDetails.emptyWeightVehicle.map((it) => {
                return {
                    id: it.packs.toString(),
                    label: `${capacityPerBattery * it.packs} ${Unit.KWH} - ${it.packs} ${batteryLabel}`,
                };
            });
            setOptions(packOptions);
            const batteryPacks = currentDetails?.defaultInstalledBatteryPackCount.toString();

            if (!isProfileSubmited) {
                dispatch(changeBatteryCapacity({ profileDetails: currentDetails, batteryPack: batteryPacks }));
            }
        }
    }, [currentDetails]);

    const handleBatteryCapacityChange = (selectedOption: string) => {
        dispatch(changeBatteryCapacity({ profileDetails: currentDetails, batteryPack: selectedOption }));
    };

    useEffect(() => {
        if (!isUndefined(batteryCapacity) && !isUndefined(options)) {
            const optionToSelect = options.find((option: any) => option.label.includes(batteryCapacity));
            handleBatteryCapacityChange(optionToSelect?.id);
        }
    }, [batteryCapacity, options]);

    return (
        <div className="display-flex" data-testid="vehicle-battery-type">
            {isCostumProfile ? (
                <LabeledNumberInput
                    integerOnly
                    className="flex-1-1-0"
                    labelId="intl-msg:smartRoute.vehicle.batteryCapacity"
                    value={batteryCapacity}
                    unit={VEHCILE_ATTRIBUTES_UNITS[HEREVehicleAttibuteNames.CHARGING_POWER]}
                    onChange={handleBatteryCapacityChange}
                />
            ) : (
                <LabeledSelect
                    className="flex-1-1-0"
                    label={<FormattedMessage id="intl-msg:smartRoute.vehicle.batteryCapacity" />}
                    optionLabels={options}
                    selectedOption={batteryCapacity?.toString()}
                    onChange={handleBatteryCapacityChange}
                />
            )}
        </div>
    );
};
