import { HttpResponse, http } from 'msw';
import { API, BASE_URL } from '../../../src/common/constants';
import { IsolineRequest } from '../../../src/store/facade/facadeApi';

const OFFSET = 0.5;

export const generateIsolineMock = (lat: number, lng: number) => {
    return {
        items: [
            {
                points: [
                    // top left
                    {
                        latitude: lat + OFFSET,
                        longitude: lng - OFFSET * 2,
                    },
                    // bottom left
                    {
                        latitude: lat - OFFSET,
                        longitude: lng - OFFSET * 2,
                    },
                    // bottom right
                    {
                        latitude: lat - OFFSET,
                        longitude: lng + OFFSET * 2,
                    },
                    // top right
                    {
                        latitude: lat + OFFSET,
                        longitude: lng + OFFSET * 2,
                    },
                ],
            },
        ],
    };
};

// eslint-disable-next-line max-len
const RESOURCE_URL = `${BASE_URL}${API.endpoints.isoline}`;

export const mockedIsolineResponse = http.post(RESOURCE_URL, async ({ request }) => {
    const requestBody = (await request.json()) as IsolineRequest;
    const lat = requestBody?.origin?.latitude;
    const lng = requestBody?.origin?.longitude;

    return HttpResponse.json(generateIsolineMock(lat, lng));
});

const isolineEmptyResponse = http.post(RESOURCE_URL, () => {
    return HttpResponse.json({ items: [] });
});

const isolineErrorResponse = http.post(RESOURCE_URL, () => {
    return HttpResponse.json(null, { status: 500 });
});

export const IsolineResponse = {
    success: mockedIsolineResponse,
    error: isolineErrorResponse,
    empty: isolineEmptyResponse
};
