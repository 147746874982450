import SmoothScrollbars from '@rio-cloud/rio-uikit/components/smoothScrollbars/SmoothScrollbars';
import Fade from '@rio-cloud/rio-uikit/Fade';
import { useNavigate } from 'react-router-dom';
import { DEFAULT_ROUTE, VEHICLE_ROUTE } from '../../common/constants';
import { PageNavigationBar } from '../../components/PageNavigationBar/PageNavigationBar';
import { useAppDispatch, useAppSelector } from '../../configuration/setup/hooks';
import { VehicleOptions } from '../../containers/VehicleOptions/VehicleOptions';
import { selectPageDisplayed } from '../../store/app/appSelectors';
import { updatePage } from '../../store/app/appSlice';

export const VehicleOptionsPage = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const showContent = useAppSelector(selectPageDisplayed) === VEHICLE_ROUTE;

    const handleExitNav = () => {
        navigate(DEFAULT_ROUTE, { replace: false });
    };

    const handleClickGoBackNav = () => {
        dispatch(updatePage(DEFAULT_ROUTE));
    };

    return (
        <Fade animationStyle={Fade.PAGE_BACK} show={showContent} onExitComplete={handleExitNav}>
            <div className="display-flex flex-column height-100vh margin-15" data-testid="route-summary-page">
                <div className="margin-left--20" data-testid="summary-back-button">
                    <PageNavigationBar
                        title="intl-msg:smartRoute.generic.vehicleSettings"
                        navBack={handleClickGoBackNav}
                    />
                </div>
                <SmoothScrollbars>
                    <div className="display-flex flex-column" data-testid="vehicle-options-page">
                        <VehicleOptions />
                    </div>
                </SmoothScrollbars>
            </div>
        </Fade>
    );
};
