import { createSelector } from 'reselect';
import { RootState } from '../../configuration/setup/store';
import { selectIsRoutingActive, selectRoute } from '../route/routeSelectors';
import { selectAreWaypointsInvalid } from '../search/searchSelectors';
import { selectHasValidBooking } from '../vehicleProfile/vehicleProfileSelectors';

export const selectApp = (state: RootState) => state.app;
export const selectSessionExpiredAcknowledged = createSelector(selectApp, (state) => state.sessionExpiredAcknowledged);
export const selectPageDisplayed = createSelector(selectApp, (state) => state.pageDisplayed);

export const selectSecondStepDisabled = createSelector(
    [selectIsRoutingActive, selectAreWaypointsInvalid, selectHasValidBooking],
    (isRoutingActive, waypointsInvalid, hasValidBooking) => {
        return !isRoutingActive || waypointsInvalid || !hasValidBooking;
    }
);

export const selectDisabledSteps = createSelector(
    [selectApp, selectRoute, selectAreWaypointsInvalid],
    (appState, routeState, waypointsInvalid) => {
        if (waypointsInvalid) {
            return 0;
        }

        if (routeState.isRoutingActive === true) {
            return 2;
        }
    }
);
