import OverlayTrigger from '@rio-cloud/rio-uikit/OverlayTrigger';
import Tooltip from '@rio-cloud/rio-uikit/Tooltip';
import { FormattedMessage } from 'react-intl';
import { useAppDispatch } from '../../configuration/setup/hooks';
import { waypointAdded, waypointsOrderInverted } from '../../store/search/searchSlice';

export const WaypointsOptions = () => {
    const dispatch = useAppDispatch();

    const handleAddDestination = () => {
        dispatch(waypointAdded(-1));
    };

    const handleInvertOrderButton = () => dispatch(waypointsOrderInverted());

    const tooltip = (
        <Tooltip id="tooltip-ignoreRestrictionsAroundWaypoints" data-testid="invert-btn-tooltip">
            <div className="text-left" data-testid="invert-btn-tooltip-label">
                <FormattedMessage id="intl-msg:smartRoute.tour.invertWaypoints" />
            </div>
        </Tooltip>
    );

    return (
        <div className="InvertWaypoints display-flex align-items-center justify-content-between">
            <button
                className="btn btn-sm btn-link btn-link-inline margin-left-0 text-color-dark"
                onClick={handleAddDestination}
            >
                <span className="rioglyph rioglyph-plus-sign text-color-dark margin-right-5" />
                <FormattedMessage id="intl-msg:smartRoute.tour.addDestination" />
            </button>
            <button className="btn btn-muted btn-icon-only justify-content-end" onClick={handleInvertOrderButton}>
                <OverlayTrigger placement="bottom" overlay={tooltip} data-testid="srp-checkbox-with-tooltip">
                    <span className="rioglyph rioglyph-sort" />
                </OverlayTrigger>
            </button>
        </div>
    );
};
