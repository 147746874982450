import { FormattedMessage } from 'react-intl';
import { LabeledSelect } from '../../components/LabeledSelect/LabeledSelect';
import { useAppDispatch, useAppSelector } from '../../configuration/setup/hooks';
import { changeEmissionClassification } from '../../store/vehicleProfile/vehicleProfileSlice';
import { EmissionClass } from '../../store/vehicleProfile/vehicleProfileTypes';
import { selectEmissionClassification } from '../../store/vehicleProfile/vehicleProfileSelectors';

const emissionClassOptionLabels = [
    { id: EmissionClass.NONE, label: <FormattedMessage id="intl-msg:smartRoute.generic.none" /> },
    {
        id: EmissionClass.EURO_I,
        label: <FormattedMessage id="intl-msg:smartRoute.vehicle.emissionClass.euroI" />,
    },
    {
        id: EmissionClass.EURO_II,
        label: <FormattedMessage id="intl-msg:smartRoute.vehicle.emissionClass.euroII" />,
    },
    {
        id: EmissionClass.EURO_III,
        label: <FormattedMessage id="intl-msg:smartRoute.vehicle.emissionClass.euroIII" />,
    },
    {
        id: EmissionClass.EURO_IV,
        label: <FormattedMessage id="intl-msg:smartRoute.vehicle.emissionClass.euroIV" />,
    },
    {
        id: EmissionClass.EURO_V,
        label: <FormattedMessage id="intl-msg:smartRoute.vehicle.emissionClass.euroV" />,
    },
    {
        id: EmissionClass.EURO_VI,
        label: <FormattedMessage id="intl-msg:smartRoute.vehicle.emissionClass.euroVI" />,
    },
    {
        id: EmissionClass.EURO_EEV,
        label: <FormattedMessage id="intl-msg:smartRoute.vehicle.emissionClass.euroEEV" />,
    },
];

export const EmissionClassSelect = () => {
    const dispatch = useAppDispatch();

    const emissionClassification = useAppSelector(selectEmissionClassification);

    const handleEmissionClassChange = (selectedOption: string) => {
        const shippedEmissionGoods = selectedOption as EmissionClass;
        dispatch(changeEmissionClassification(shippedEmissionGoods));
    };

    return (
        <div className="display-flex align-items-end" data-testid="emission-select">
            <LabeledSelect
                className="width-100pct"
                label={<FormattedMessage id="intl-msg:smartRoute.vehicle.emissionClass" />}
                optionLabels={emissionClassOptionLabels}
                selectedOption={emissionClassification}
                onChange={handleEmissionClassChange}
            />
        </div>
    );
};
