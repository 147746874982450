import isNull from 'lodash/fp/isNull';
import last from 'lodash/fp/last';
import { FormattedMessage } from 'react-intl';
import { convertStringToDateFormat } from '../../../common/utils';
import { RangeStatusIcon } from '../../../components/RangeStatusIcon/RangeStatusIcon';
import { useAppSelector } from '../../../configuration/setup/hooks';
import { ArrivalSection } from '../../../store/facade/facadeApi';
import { selectIsWaypointInRange, selectOrderedWaypoints } from '../../../store/search/searchSelectors';

type ArrivalPanelProps = {
    arrival: ArrivalSection;
    waypointId: number;
};

export const ArrivalPanel = ({ arrival, waypointId }: ArrivalPanelProps) => {
    const isWaypointInRange = useAppSelector((state) => selectIsWaypointInRange(state, waypointId));
    const date = convertStringToDateFormat(arrival.estimated_arrival_time);
    const waypoints = useAppSelector(selectOrderedWaypoints);
    const arrivalWaypoint = last(waypoints);

    return (
        <li className="timeline-element" data-testid="timeline-element">
            <div className="timeline-date margin-right-10">
                <div className="border border-width-3 border-color-white">
                    <span className="badge badge-lg badge-muted padding-5">
                        <span className="rioglyph rioglyph-finish" />
                    </span>
                </div>
            </div>
            <div className="timeline-content margin-bottom-15">
                <div className="text-medium text-size-16 text-color-darker margin-top-5 margin-bottom-5">
                    <span className="margin-right-5">
                        <FormattedMessage id="intl-msg:smartRoute.tour.arrival" />
                    </span>
                    {!isNull(isWaypointInRange) && (
                        <RangeStatusIcon isWaypointInRange={isWaypointInRange!} badgeSize="badge-sm" />
                    )}
                </div>
                <div className="display-flex flex-column gap-2">
                    <div>
                        <span className="rioglyph rioglyph-calendar text-color-gray margin-right-5" />
                        <span>{date}</span>
                    </div>
                    <div>
                        <span className="rioglyph rioglyph-map-marker text-color-gray margin-right-5" />
                        <span>{arrivalWaypoint?.address}</span>
                    </div>
                </div>
            </div>
        </li>
    );
};
