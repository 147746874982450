import { SelectOption } from '@rio-cloud/rio-uikit/Select';
import isNil from 'lodash/fp/isNil';
import { FormattedMessage } from 'react-intl';
import { LabeledSelect } from '../../components/LabeledSelect/LabeledSelect';
import { useAppSelector } from '../../configuration/setup/hooks';
import { selectIsEV, selectProfileType } from '../../store/vehicleProfile/vehicleProfileSelectors';
import { VehicleType } from '../../store/vehicleProfile/vehicleProfileTypes';

export const vehicleTypeOptionLabels = [
    {
        id: VehicleType.TRUCK_AND_TRAILER_40T,
        label: <FormattedMessage id="intl-msg:smartRoute.vehicle.vehicleType.truckAndTrailer40T" />,
    },
    {
        id: VehicleType.TRUCK_11T,
        label: <FormattedMessage id="intl-msg:smartRoute.vehicle.vehicleType.truck11T" />,
    },
    {
        id: VehicleType.TRUCK_7T5,
        label: <FormattedMessage id="intl-msg:smartRoute.vehicle.vehicleType.truck7T5" />,
    },
    {
        id: VehicleType.TRANSPORTER_3T5,
        label: <FormattedMessage id="intl-msg:smartRoute.vehicle.vehicleType.transporter3T5" />,
    },
    { id: VehicleType.CAR, label: <FormattedMessage id="intl-msg:smartRoute.vehicle.vehicleType.car" /> },
    {
        id: VehicleType.CUSTOM_ICE,
        label: <FormattedMessage id="intl-msg:smartRoute.vehicle.vehicleType.customICE" />,
    },
];

export const customVehicleOption = {
    id: VehicleType.CUSTOM_EV,
    label: <FormattedMessage id="intl-msg:smartRoute.vehicle.vehicleType.customEV" />,
};

interface ProfileSelectProps {
    evOptions: SelectOption[];
    iceOptions: SelectOption[];
    handleProfileChange: (option: string) => any;
}

export const ProfileSelect = ({ evOptions, iceOptions, handleProfileChange }: ProfileSelectProps) => {
    const isEV = useAppSelector(selectIsEV).value;
    const vehicleType = useAppSelector(selectProfileType);

    return (
        <div data-testid="vehicle-type">
            {(isEV ? !isNil(evOptions) : !isNil(iceOptions)) && (
                <LabeledSelect
                    className="width-100pct"
                    label={<FormattedMessage id="intl-msg:smartRoute.vehicle.profile" />}
                    optionLabels={isEV ? evOptions : iceOptions}
                    selectedOption={vehicleType}
                    placeholder={<FormattedMessage id="intl-msg:smartRoute.vehicle.selectVehicleType" />}
                    onChange={(option) => handleProfileChange(option)}
                />
            )}
        </div>
    );
};
