import { CheckboxOption } from '../../components/CheckboxOption/CheckboxOption';
import { useAppDispatch, useAppSelector } from '../../configuration/setup/hooks';
import { selectRouteConstraints } from '../../store/route/routeSelectors';
import { RouteConstraints as ConstraintsType, updateRouteConstraints } from '../../store/route/routeSlice';

export const RouteConstraints = () => {
    const dispatch = useAppDispatch();

    const routeConstraints = useAppSelector(selectRouteConstraints);

    const handleCheckboxChange = (change: Partial<ConstraintsType>) => {
        dispatch(updateRouteConstraints({ ...routeConstraints, ...change }));
    };

    return (
        <div
            className="display-flex gap-5 margin-bottom-10"
            style={{ flexDirection: 'column' }}
            data-testid="route-constraints"
        >
            <CheckboxOption
                intlId="intl-msg:smartRoute.tour.restrictions.avoidAccessHighway"
                checked={routeConstraints.isAvoidAccessHighway}
                onToggle={(checked) => handleCheckboxChange({ isAvoidAccessHighway: checked })}
            />
            <CheckboxOption
                intlId="intl-msg:smartRoute.tour.restrictions.avoidTollRoads"
                checked={routeConstraints.isAvoidTollRoads}
                onToggle={(checked) => handleCheckboxChange({ isAvoidTollRoads: checked })}
            />
            <CheckboxOption
                intlId="intl-msg:smartRoute.tour.restrictions.avoidFerry"
                checked={routeConstraints.isAvoidFerry}
                onToggle={(checked) => handleCheckboxChange({ isAvoidFerry: checked })}
            />
            <CheckboxOption
                intlId="intl-msg:smartRoute.tour.restrictions.avoidTunnel"
                checked={routeConstraints.isAvoidTunnel}
                onToggle={(checked) => handleCheckboxChange({ isAvoidTunnel: checked })}
            />
            <CheckboxOption
                intlId="intl-msg:smartRoute.tour.restrictions.avoidRouteAlternatives"
                checked={routeConstraints.isAvoidRouteAlternatives}
                onToggle={(checked) => handleCheckboxChange({ isAvoidRouteAlternatives: checked })}
            />
        </div>
    );
};
