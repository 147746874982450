import ApplicationLayout from '@rio-cloud/rio-uikit/ApplicationLayout';
import NotificationsContainer from '@rio-cloud/rio-uikit/NotificationsContainer';
import { FlagsProvider } from 'react-feature-flags';
import { IntlProvider } from 'react-intl';
import { Outlet } from 'react-router-dom';
import { Upselling } from '../../components/Upselling/Upselling';
import { config } from '../../configuration/config';
import { useAppDispatch, useAppSelector } from '../../configuration/setup/hooks';
import { fetchHereSettings } from '../../features/fetchData/fetchHereSettings';
import { useAccountSubscription } from '../../hooks/AccountSubscriptionHooks';
import { useEffectOnce } from '../../hooks/hooks';
import { useWidgetListener } from '../../hooks/widgetHooks';
import { DefaultRedirect } from '../../routes/DefaultRedirect';
import { selectDisplayMessages, selectLocale } from '../../store/lang/langSelectors';
import { sendMessage } from '../../store/widget/widgetServices';
import { widgetActions } from '../../store/widget/widgetSlice';
import { useGetFeatureTogglesQuery } from '../../store/facade/facadeApi';
import { FEATURE_TOGGLES_POLLING_FREQ } from '../../common/constants';
import { trace } from '../../configuration/setup/trace';
import { buildFeatureFlagsStructure, FeatureFlag, ToggleFlag } from '../../../test/utils/utilsFeatureFlags';
import { useEffect, useState } from 'react';

export const AppLayout = () => {
    const dispatch = useAppDispatch();
    const userLocale = useAppSelector(selectLocale);
    const displayMessages = useAppSelector(selectDisplayMessages);

    useWidgetListener();

    useEffectOnce(() => {
        sendMessage(widgetActions.widgetLoaded());
        dispatch(fetchHereSettings);
    });

    const { subscribed, isLoading } = useAccountSubscription();

    const { data: toggleData, isError } = useGetFeatureTogglesQuery(undefined, {
        pollingInterval: FEATURE_TOGGLES_POLLING_FREQ * 1000,
    });
    const [featureFlags, setFeatureFlags] = useState<FeatureFlag[]>();

    useEffect(() => {
        if (toggleData) {
            setFeatureFlags(buildFeatureFlagsStructure(toggleData as ToggleFlag));
            trace.info('Fetched account feature toggles');
        }
        if (isError) {
            throw new Error('Was not able to fetch the account feature toggles');
        }
    }, [toggleData, isError]);

    return (
        <IntlProvider
            locale={userLocale}
            defaultLocale={config.login.defaultLocale}
            key={userLocale}
            messages={displayMessages}
        >
            <FlagsProvider value={featureFlags}>
                <ApplicationLayout data-testid="app-layout">
                    <NotificationsContainer />
                    {!isLoading && subscribed && (
                        <div className="display-flex flex-column height-100vh" data-testid="app-layout-content">
                            <Outlet />
                        </div>
                    )}
                    {!isLoading && !subscribed && <Upselling />}
                    <DefaultRedirect />
                </ApplicationLayout>
            </FlagsProvider>
        </IntlProvider>
    );
};
