import first from 'lodash/fp/first';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { convertStringToDateFormat } from '../../../common/utils';
import { useAppDispatch, useAppSelector } from '../../../configuration/setup/hooks';
import { trace } from '../../../configuration/setup/trace';
import { resolveAddress } from '../../../features/fetchData/resolveAddress';
import { DepartureSection } from '../../../store/facade/facadeApi';
import { selectOrderedWaypoints } from '../../../store/search/searchSelectors';
import { WaypointType } from '../../../store/search/searchSlice';

export const getWindInfo = (value: number) => {
    return value > 20 ? 'Strong winds' : 'Mild winds';
};

type DepartureSectionProps = {
    departure: DepartureSection;
};

export const DeparturePanel = ({ departure }: DepartureSectionProps) => {
    const intl = useIntl();
    const dispatch = useAppDispatch();

    const [isFirstWpVehicleLocation, setIsFirstWpVehicleLocation] = useState<boolean>();
    const [vehicleAddress, setVehicleAddress] = useState<string>();

    const date = convertStringToDateFormat(departure.departure_time);
    const waypoints = useAppSelector(selectOrderedWaypoints);
    const departureWaypoint = first(waypoints);

    const getVehicleLocation = async () => {
        try {
            const eventObj = {
                type: 'EVENT_SMART_ROUTING',
                payload: {
                    coordinates: departureWaypoint?.coordinates,
                },
            };

            const resolvedAddress = await dispatch(resolveAddress(eventObj));
            setVehicleAddress(resolvedAddress);
        } catch (error) {
            trace.error('Could not resolve vehicle address: ', error);
        }
    };

    useEffect(() => {
        if (
            departureWaypoint &&
            (departureWaypoint.type === WaypointType.VEHICLE_LOCATION ||
                departureWaypoint.address === intl.formatMessage({ id: 'intl-msg:smartRoute.tour.useVehicleLocation' }))
        ) {
            setIsFirstWpVehicleLocation(true);
            getVehicleLocation();
        } else {
            setIsFirstWpVehicleLocation(false);
        }
    });

    return (
        <li className="timeline-element" data-testid="timeline-element">
            <div className="timeline-date margin-right-10">
                <div className="border border-width-3 border-color-white">
                    <span className="badge badge-lg badge-muted padding-5">
                        <span className="rioglyph rioglyph-start" />
                    </span>
                </div>
            </div>
            <div className="timeline-content margin-bottom-15">
                <div className="text-medium text-size-16 text-color-darker margin-top-5 margin-bottom-5">
                    <FormattedMessage id="intl-msg:smartRoute.tour.departure" />
                </div>
                <div className="display-flex flex-column gap-2">
                    <div>
                        <span className="rioglyph rioglyph-calendar text-color-gray margin-right-5" />
                        <span>{date}</span>
                    </div>
                    <div>
                        <span className="rioglyph rioglyph-map-marker text-color-gray margin-right-5" />
                        <span>{isFirstWpVehicleLocation ? vehicleAddress : departureWaypoint?.address}</span>
                    </div>
                </div>
            </div>
        </li>
    );
};
