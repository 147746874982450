import accountSubscriptionResource from '../resources/accountSubscriptionResource';
import assetSubscriptionResource from '../resources/AssetSubscriptionResource';
import autosuggestHEREResource from '../resources/autosuggestHEREResource';
import chargingStationAutocomplete from '../resources/chargingStationAutocomplete';
import featureTogglesResource from '../resources/featureTogglesResource';
import { IsolineResponse } from '../resources/isolineResource';
import operatorsListResource from '../resources/operatorsListResource';
import revgeocodeHEREResource from '../resources/revgeocodeHEREResource';
import routeResource from '../resources/routeResource';
import { errorHandler as sendToVehicleErrorHandler } from '../resources/sendToVehicleHandler';
import vehicleDataResource from '../resources/vehicleDataResource';
import vehicleProfileDetails from '../resources/vehicleProfileDetailsResource';
import vehicleProfilesList from '../resources/vehicleProfileListResource';

export const handlers = [
    vehicleDataResource,
    routeResource,
    autosuggestHEREResource,
    IsolineResponse.success,
    accountSubscriptionResource,
    assetSubscriptionResource,
    featureTogglesResource,
    operatorsListResource,
    chargingStationAutocomplete,
    revgeocodeHEREResource,
    vehicleProfilesList,
    vehicleProfileDetails,
    sendToVehicleErrorHandler,
];
