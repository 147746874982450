import Tag from '@rio-cloud/rio-uikit/components/tag/Tag';
import TagList from '@rio-cloud/rio-uikit/components/tag/TagList';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useAppDispatch, useAppSelector } from '../../configuration/setup/hooks';
import { selectChargingOptionsGrading } from '../../store/charging/chargingSelectors';
import { updateChargingOptionsGrading } from '../../store/charging/chargingSlice';

export const GradingOptions = () => {
    const dispatch = useAppDispatch();

    const chargingOptionsGrading = useAppSelector(selectChargingOptionsGrading);

    const [truckReady, setTruckReady] = useState<boolean>(chargingOptionsGrading.truckReady);
    const [limited, setLimited] = useState<boolean>(chargingOptionsGrading.limited);
    const [unspecified, setUnspecified] = useState<boolean>(chargingOptionsGrading.unspecified);

    useEffect(() => {
        dispatch(
            updateChargingOptionsGrading({
                truckReady,
                limited,
                unspecified,
            })
        );
    }, [truckReady, limited, unspecified]);

    return (
        <div className="align-items-end margin-bottom-10" data-testid="grading-options">
            <label className="control-label" data-testid="charging-grading">
                <FormattedMessage id="intl-msg:smartRoute.chargingStation.grading" />
            </label>
            <TagList inline autoTagWidth>
                <Tag
                    clickable
                    selectable
                    round={false}
                    active={truckReady}
                    onClick={() => setTruckReady((previousState) => !previousState)}
                    data-testid="gradind-tag"
                >
                    <FormattedMessage id="intl-msg:smartRoute.chargingStation.grading.truckReady" />
                </Tag>

                <Tag
                    clickable
                    selectable
                    round={false}
                    active={limited}
                    onClick={() => setLimited((previousState) => !previousState)}
                    data-testid="gradind-tag"
                >
                    <FormattedMessage id="intl-msg:smartRoute.chargingStation.grading.limited" />
                </Tag>

                <Tag
                    clickable
                    selectable
                    round={false}
                    active={unspecified}
                    onClick={() => setUnspecified((previousState) => !previousState)}
                    data-testid="gradind-tag"
                >
                    <FormattedMessage id="intl-msg:smartRoute.chargingStation.grading.unspecified" />
                </Tag>
            </TagList>
        </div>
    );
};
