import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { POWER_LOW, POWER_MEDIUM } from '../../common/constants';
import { CardCheckboxOption } from '../../components/CardCheckboxOption/CardCheckboxOption';
import { useAppDispatch, useAppSelector } from '../../configuration/setup/hooks';
import { selectChargingPower } from '../../store/charging/chargingSelectors';
import { updateChargingPower } from '../../store/charging/chargingSlice';
import { HEREVehicleAttibuteNames, VEHCILE_ATTRIBUTES_UNITS } from '../../store/vehicleProfile/vehicleProfileTypes';

export const unit = VEHCILE_ATTRIBUTES_UNITS[HEREVehicleAttibuteNames.CHARGING_POWER];

export const PowerOptions = () => {
    const intl = useIntl();
    const dispatch = useAppDispatch();

    const chargingPower = useAppSelector(selectChargingPower);

    const [lowPower, setLowPower] = useState<boolean>(chargingPower.lowPower);
    const [midPower, setMidPower] = useState<boolean>(chargingPower.midPower);
    const [highPower, setHighPower] = useState<boolean>(chargingPower.highPower);

    const getCheckboxClasses = (isSelected: boolean) =>
        `padding-10 user-select-none border rounded ${isSelected ? 'border-color-highlight' : ''}`;

    useEffect(() => {
        dispatch(
            updateChargingPower({
                lowPower,
                midPower,
                highPower,
            })
        );
    }, [lowPower, midPower, highPower]);

    return (
        <div className="align-items-end margin-bottom-10" data-testid="charging-power-options">
            <label className="control-label" data-testid="charging-grading">
                <FormattedMessage id="intl-msg:smartRoute.generic.chargingPower" />
            </label>
            <div className="display-flex gap-10 margin-bottom-10" style={{ flexDirection: 'column' }}>
                <div data-testid="charging-power-low">
                    <CardCheckboxOption
                        cardIntlTitle="intl-msg:smartRoute.power.low"
                        cardTextSubTitle={`${intl.formatMessage({
                            id: 'intl-msg:smartRoute.generic.lessThan',
                        })} ${POWER_LOW} ${unit}`}
                        checked={lowPower}
                        onToggle={() => setLowPower((previousState) => !previousState)}
                        checkboxClasses={getCheckboxClasses(lowPower)}
                    />
                </div>
                <div data-testid="charging-power-medium">
                    <CardCheckboxOption
                        cardIntlTitle="intl-msg:smartRoute.power.medium"
                        cardTextSubTitle={`${intl.formatMessage({
                            id: 'intl-msg:smartRoute.generic.rangeFrom',
                        })} ${POWER_LOW} ${unit} to ${POWER_MEDIUM} ${unit}`}
                        checked={midPower}
                        onToggle={() => setMidPower((previousState) => !previousState)}
                        checkboxClasses={getCheckboxClasses(midPower)}
                    />
                </div>
                <div data-testid="charging-power-high">
                    <CardCheckboxOption
                        cardIntlTitle="intl-msg:smartRoute.power.high"
                        cardTextSubTitle={`${intl.formatMessage({
                            id: 'intl-msg:smartRoute.generic.moreThan',
                        })} ${POWER_MEDIUM} ${unit}`}
                        checked={highPower}
                        onToggle={() => setHighPower((previousState) => !previousState)}
                        checkboxClasses={getCheckboxClasses(highPower)}
                    />
                </div>
            </div>
        </div>
    );
};
