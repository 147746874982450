import { useEffect, useState } from 'react';
import { Unit } from '../../common/constants';
import { LabeledNumberInput } from '../../components/LabeledNumberInput/LabeledNumberInput';
import { useAppDispatch, useAppSelector } from '../../configuration/setup/hooks';
import { selectStateOfCharge } from '../../store/charging/chargingSelectors';
import { updateStateOfCharge } from '../../store/charging/chargingSlice';

export const SoCOptions = () => {
    const dispatch = useAppDispatch();

    const stateOfCharge = useAppSelector(selectStateOfCharge);

    const [minSoC, setMinSoC] = useState<number>(stateOfCharge.minSoC);
    const [maxSoC, setMaxSoC] = useState<number>(stateOfCharge.maxSoC);
    const [originSoC, setOriginSoC] = useState<number>(stateOfCharge.originSoC);
    const [destinationSoC, setDestinationSoC] = useState<number>(stateOfCharge.destinationSoC);

    useEffect(() => {
        dispatch(
            updateStateOfCharge({
                minSoC,
                maxSoC,
                originSoC,
                destinationSoC,
            })
        );
    }, [minSoC, maxSoC, originSoC, destinationSoC]);

    return (
        <>
            <div className="display-flex align-items-end gap-10" data-testid="min-max-soc">
                <LabeledNumberInput
                    className="flex-1-1-0"
                    labelId="intl-msg:smartRoute.vehicle.minSoC"
                    value={minSoC}
                    unit={Unit.pct}
                    integerOnly
                    onChange={(value: number) => setMinSoC(value)}
                />
                <LabeledNumberInput
                    className="flex-1-1-0"
                    labelId="intl-msg:smartRoute.vehicle.maxSoC"
                    value={maxSoC}
                    unit={Unit.pct}
                    integerOnly
                    onChange={(value: number) => setMaxSoC(value)}
                />
            </div>

            <div className="display-flex align-items-end gap-10" data-testid="origin-destination-soc">
                <LabeledNumberInput
                    className="flex-1-1-0"
                    labelId="intl-msg:smartRoute.vehicle.SoCOrigin"
                    value={originSoC}
                    unit={Unit.pct}
                    integerOnly
                    onChange={(value: number) => setOriginSoC(value)}
                />
                <LabeledNumberInput
                    className="flex-1-1-0"
                    labelId="intl-msg:smartRoute.vehicle.SoCODestination"
                    value={destinationSoC}
                    unit={Unit.pct}
                    integerOnly
                    onChange={(value: number) => setDestinationSoC(value)}
                />
            </div>
        </>
    );
};
