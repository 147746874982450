import { HttpResponse, http } from 'msw';
import { API } from '../../../src/common/constants';
import { config } from '../../../src/configuration/config';
import { operatorsListMock } from '../data/operatorsListMock';

// eslint-disable-next-line max-len
const URL = `${config.backend.BACKEND_SERVICE}/${API.serviceName}/${API.version.latest}${API.endpoints.chargeStationOperators}`;

export default http.get(URL, async () => {
    const operators = operatorsListMock();
    return HttpResponse.json(operators);
});
