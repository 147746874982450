import OverlayTrigger from '@rio-cloud/rio-uikit/OverlayTrigger';
import Tooltip from '@rio-cloud/rio-uikit/Tooltip';
import isNull from 'lodash/fp/isNull';
import { FormattedMessage } from 'react-intl';

const rangeTips = [
    'intl-msg:smartRoute.vehicle.isReachable.tooltip',
    'intl-msg:smartRoute.vehicle.isNotReachable.tooltip',
];

const getStatusColor = (isWaypointInRange: boolean) => {
    const inRangeColor = 'badge-success';
    const outOfRangeIcon = 'badge-warning';

    return isWaypointInRange ? inRangeColor : outOfRangeIcon;
};

const getStatusIcon = (isWaypointInRange: boolean) => {
    const inRangeIcon = 'rioglyph rioglyph-ok';
    const outOfRangeIcon = 'rioglyph rioglyph-warning-sign';

    return isWaypointInRange ? inRangeIcon : outOfRangeIcon;
};

export const RangeStatusIcon = ({ isWaypointInRange, badgeSize }: RangeStatusIconProps) => {
    if (isNull(isWaypointInRange)) {
        return;
    }

    const tooltipContent = isWaypointInRange ? rangeTips[0] : rangeTips[1];

    const tooltip = (
        <Tooltip id="tooltip-ignoreRestrictionsAroundWaypoints" width={250} data-testid="srp-range-tooltip">
            <div className="text-left" data-testid="srp-range-tooltip-label">
                <FormattedMessage id={tooltipContent} />
            </div>
        </Tooltip>
    );

    return (
        <OverlayTrigger
            key={crypto.randomUUID()}
            placement="bottom"
            overlay={tooltip}
            data-testid="srp-range-with-tooltip"
        >
            <span
                className={`badge ${badgeSize} ${getStatusColor(isWaypointInRange)} padding-5 scale-80`}
                data-testid="srp-range-circle"
            >
                <span className={`rioglyph ${getStatusIcon(isWaypointInRange)}`} data-testid="srp-range-icon" />
            </span>
        </OverlayTrigger>
    );
};

interface RangeStatusIconProps {
    isWaypointInRange: boolean | null;
    badgeSize: string;
}
