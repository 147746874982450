import Checkbox from '@rio-cloud/rio-uikit/Checkbox';
import OverlayTrigger from '@rio-cloud/rio-uikit/OverlayTrigger';
import Tooltip from '@rio-cloud/rio-uikit/Tooltip';
import { FormattedMessage } from 'react-intl';

export const CheckboxOption = ({ intlId, checked, disabled, onToggle, tooltipContent }: CheckboxOptionProps) => {
    const tooltip = (
        <Tooltip id="tooltip-ignoreRestrictionsAroundWaypoints" width={250} data-testid="srp-checkbox-tooltip">
            <div className="text-left" data-testid="srp-checkbox-tooltip-label">
                {tooltipContent}
            </div>
        </Tooltip>
    );

    return (
        <Checkbox
            checked={checked}
            disabled={disabled ?? false}
            onClick={() => onToggle(!checked)}
            data-testid="srp-checkbox"
        >
            {tooltipContent ? (
                <OverlayTrigger placement="bottom" overlay={tooltip} data-testid="srp-checkbox-with-tooltip">
                    <FormattedMessage id={intlId} />
                </OverlayTrigger>
            ) : (
                <FormattedMessage id={intlId} data-testid="srp-checkbox-button-without-tooltip" />
            )}
        </Checkbox>
    );
};

interface CheckboxOptionProps {
    intlId: string;
    checked: boolean;
    disabled?: boolean;
    onToggle: (checked: boolean) => void;
    tooltipContent?: string;
}
