import getSymbolFromCurrency from 'currency-symbol-map';
import first from 'lodash/fp/first';
import isEmpty from 'lodash/fp/isEmpty';
import isUndefined from 'lodash/fp/isUndefined';
import last from 'lodash/fp/last';
import { v4 as uuidv4 } from 'uuid';
import { Unit, WINDOW_BOUNDING_BOX_DEGREES } from '../../common/constants';
import { Coords } from '../../common/types';
import { convertMetersIntoKilometers, convertSecondsIntoHHmm } from '../../common/utils';
import { RootDispatch, RootState, store } from '../../configuration/setup/store';
import {
    AdditionalStopSection,
    ArrivalSection,
    ChargingStation,
    ChargingStationSection,
    DepartureSection,
    DrivingSection,
    RouteSection,
    SectionType,
} from '../../store/facade/facadeApi';
import { selectSelectedRoute, selectSuggestedRoutes, selectVehicleCosts } from '../../store/route/routeSelectors';
import { suggestedRoutesRemoved } from '../../store/route/routeSlice';
import { renderShapes } from '../../store/widget/actions/renderShapes';
import { sendMessage } from '../../store/widget/widgetServices';
import { widgetActions } from '../../store/widget/widgetSlice';
import { WaypointWithCoordinates } from '../fetchData/createRouteParameters';
import { mapToCoords, Route, RouteSummary, TripDetails } from '../fetchData/mappers/mapRoutes';

interface MarkerProps {
    active: boolean;
    iconNames: string[];
    markerColor: string;
    name?: string;
    clickable?: boolean;
    fixed?: boolean;
}

export interface Marker {
    id: string;
    position: Coords;
    markerProps: MarkerProps;
}

export interface Segment {
    points: Coords[];
    alternative: boolean;
    reduced?: boolean;
    showArrows?: boolean;
}

const createWaypointMarker = (waypoint: WaypointWithCoordinates, icon: string): Marker => ({
    id: uuidv4(),
    position: waypoint.coordinates,
    markerProps: {
        active: false,
        iconNames: [icon],
        markerColor: 'bg-map-marker-route',
        clickable: false,
    },
});

const createRouteInfoMarker = (coordinates: Coords, isActive: boolean, info: string): Marker => {
    return {
        id: uuidv4(),
        position: coordinates,
        markerProps: {
            active: isActive,
            name: info,
            iconNames: ['route'],
            markerColor: 'bg-map-marker-route',
            clickable: false,
            fixed: true,
        },
    };
};

const getDistributedMarkerPosition = (coordinates: Coords[], numberOfRoutes: number, iteration: number): Coords => {
    const divisor = numberOfRoutes + 1;
    const step = iteration + 1;
    return coordinates[Math.floor((coordinates.length / divisor) * step)];
};

export const createRouteInfo = (infoData: RouteSummary, state: RootState): string => {
    const routeDuration = convertSecondsIntoHHmm(infoData?.duration);
    const routeDistance = convertMetersIntoKilometers(infoData?.distance)?.toString();
    const routeCosts = infoData?.total_cost;
    const vehicleCosts = selectVehicleCosts(state);
    const routeCurrency = getSymbolFromCurrency(vehicleCosts.currency);

    return `${routeDuration} | ${routeDistance}${Unit.km} | ${routeCosts}${routeCurrency}`;
};

const createRouteMarkers = (routes: Route[], selectedRoute: number, state: RootState): Marker[] => {
    const route = routes.at(selectedRoute);

    if (isUndefined(route)) {
        return [];
    }

    const waypoints = route.waypoints;
    const chargingStops = route.chargingStops;
    const restingStops = route.restingStops;

    if (isEmpty(waypoints) && isEmpty(chargingStops) && isEmpty(restingStops)) {
        return [];
    }

    const firstMarker = createWaypointMarker(first(waypoints)!, 'start');
    const lastMarker = createWaypointMarker(last(waypoints)!, 'finish');

    const intermediaryWaypoints = waypoints.slice(1, waypoints.length - 1);
    const intermediaryMarkers = intermediaryWaypoints.map((waypoint) => createWaypointMarker(waypoint, 'arrow-down'));
    const charginStationMarkers = chargingStops.map((chargingStop: any) =>
        createWaypointMarker(chargingStop, 'filling-e-station')
    );
    const restingStationMarkers = restingStops.map((restingStop: any) =>
        createWaypointMarker(restingStop, 'status-resting')
    );

    const SummaryMarker = createRouteInfoMarker(
        getDistributedMarkerPosition(route.routeCoordinates, routes.length, selectedRoute),
        selectedRoute === selectedRoute,
        createRouteInfo(route.summary, state)
    );

    return [
        firstMarker,
        lastMarker,
        ...intermediaryMarkers,
        ...charginStationMarkers,
        ...restingStationMarkers,
        SummaryMarker,
    ];
};

const createTripMarkers = (routes: Route[], selectedRoute: number, state: RootState): Marker[] => {
    const waypoints = routes[selectedRoute].waypoints;
    const chargingStops = routes[selectedRoute].chargingStops;
    const restingStops = routes[selectedRoute].restingStops;

    if (isEmpty(waypoints) && isEmpty(chargingStops) && isEmpty(restingStops)) {
        return [];
    }

    const firstMarker = createWaypointMarker(first(waypoints)!, 'start');
    const lastMarker = createWaypointMarker(last(waypoints)!, 'finish');

    const intermediaryWaypoints = waypoints.slice(1, waypoints.length - 1);
    const intermediaryMarkers = intermediaryWaypoints.map((waypoint) => createWaypointMarker(waypoint, 'arrow-down'));
    const charginStationMarkers = chargingStops.map((chargingStop: any) =>
        createWaypointMarker(chargingStop, 'filling-e-station')
    );
    const restingStationMarkers = restingStops.map((restingStop: any) =>
        createWaypointMarker(restingStop, 'status-resting')
    );

    const routeInfoMarkers = routes.map((route, index) => {
        const markerInfo = createRouteInfoMarker(
            getDistributedMarkerPosition(route.routeCoordinates, routes.length, index),
            index === selectedRoute,
            createRouteInfo(route.summary, state)
        );

        return markerInfo;
    });

    return [
        firstMarker,
        lastMarker,
        ...intermediaryMarkers,
        ...charginStationMarkers,
        ...restingStationMarkers,
        ...routeInfoMarkers,
    ];
};

const createRouteSegments = (routes: Route[], selectedRoute: number, state: RootState): Segment[] | null => {
    const route = routes.at(selectedRoute);

    if (isUndefined(route)) {
        return null;
    }

    const routeSegment = {
        points: route.routeCoordinates,
        alternative: false,
        reduced: false,
        showArrows: true,
    };

    return [routeSegment];
};

const createTripSegments = (routes: Route[], selectedRoute: number, state: RootState): Segment[] => {
    const segments = routes.map((route, index) => {
        return {
            points: route.routeCoordinates,
            alternative: selectedRoute !== index,
            reduced: selectedRoute !== index,
            showArrows: selectedRoute === index,
        };
    });

    return segments;
};

const requestBoundingBox = (routes: Route[]) => {
    let minLat = Number.MAX_SAFE_INTEGER;
    let maxLat = Number.MIN_SAFE_INTEGER;
    let minLong = Number.MAX_SAFE_INTEGER;
    let maxLong = Number.MIN_SAFE_INTEGER;

    routes.forEach((route) => {
        route.routeCoordinates.forEach((coords) => {
            if (coords.lat < minLat) {
                minLat = coords.lat;
            } else if (coords.lat > maxLat) {
                maxLat = coords.lat;
            }

            if (coords.lng < minLong) {
                minLong = coords.lng;
            } else if (coords.lng > maxLong) {
                maxLong = coords.lng;
            }
        });
    });

    sendMessage(
        widgetActions.setBoundingBox({
            bbox: {
                topLeft: {
                    lat: maxLat + WINDOW_BOUNDING_BOX_DEGREES,
                    lng: minLong - WINDOW_BOUNDING_BOX_DEGREES,
                },
                bottomRight: {
                    lat: minLat - WINDOW_BOUNDING_BOX_DEGREES,
                    lng: maxLong + WINDOW_BOUNDING_BOX_DEGREES,
                },
            },
        })
    );
};

export const propagateRoutes = (mainRouteIndex?: number) => (dispatch: RootDispatch, getState: () => RootState) => {
    const state = getState();
    const trip = selectSuggestedRoutes(state);
    const currentSelectedRoute = selectSelectedRoute(state);
    const routes = getRoutes(trip, currentSelectedRoute);

    const indexToRender = mainRouteIndex ?? currentSelectedRoute;

    const route = {
        segments: createTripSegments(routes, indexToRender, state),
        markers: createTripMarkers(routes, indexToRender, state),
    };

    dispatch(propagateBoundingBox);
    sendMessage(widgetActions.renderRoute(route));
};

export const propagateRoute = (routeIndex?: number) => (dispatch: RootDispatch, getState: () => RootState) => {
    const state = getState();
    const trip = selectSuggestedRoutes(state);
    const currentSelectedRoute = selectSelectedRoute(state);
    const routes = getRoutes(trip, currentSelectedRoute);

    const route = {
        segments: createRouteSegments(routes, routeIndex, state),
        markers: createRouteMarkers(routes, routeIndex, state),
    };

    dispatch(propagateBoundingBox);
    sendMessage(widgetActions.renderRoute(route));
};

const getRoutes = (trip: TripDetails | undefined, currentSelectedRoute: number | null) => {
    if (!trip || isEmpty(trip) || isUndefined(currentSelectedRoute)) {
        return;
    }

    const routes = getRoutesFromTrip(trip);

    if (isEmpty(routes)) {
        return;
    }

    return routes;
};

export const getNearChargingStations = (trip: TripDetails): ChargingStation[] => {
    const allNearChargingStations = trip.routes
        .flatMap((route) => route.sections)
        .filter((section) => section.sectionType === SectionType.Driving)
        .map((section) => section.info as DrivingSection)
        .flatMap((drivingSection) => drivingSection.nearChargingStations)
        .filter((station) => !isUndefined(station)) as ChargingStation[];
    return [...new Map(allNearChargingStations.map((station) => [station.id, station])).values()];
};

export const getRoutesFromTrip = (trip: TripDetails) =>
    trip.routes.map((route) => ({
        waypoints: getWaypointsFromRouteSections(route.sections),
        chargingStops: getChargingStopsFromRouteSections(route.sections),
        restingStops: getAditionalStopsFromRouteSections(route.sections),
        routeCoordinates: route.drivingCoordinates,
        summary: route.summary,
    }));

export const getWaypointsFromRouteSections = (sections: RouteSection[]) =>
    sections
        .filter(
            (section) => section.sectionType === SectionType.Arrival || section.sectionType === SectionType.Departure
        )
        .map((section) => section.info as ArrivalSection | DepartureSection)
        .map((section, idx) => {
            const result = {
                id: idx,
                address: section.address,
                coordinates: mapToCoords(section.location),
            };
            return result;
        });

export const getChargingStopsFromRouteSections = (sections: RouteSection[]) =>
    sections
        .filter((section) => section.sectionType === SectionType.ChargingStation)
        .map((section) => section.info as ChargingStationSection)
        .map((section, idx) => {
            const result = {
                id: idx,
                sectionType: SectionType.ChargingStation,
                address: section.charging_station.address,
                coordinates: mapToCoords(section.charging_station.location),
            };
            return result;
        });

export const getAditionalStopsFromRouteSections = (sections: RouteSection[]) =>
    sections
        .filter((section) => section.sectionType === SectionType.AdditionalStop)
        .map((section) => section.info as AdditionalStopSection)
        .map((section, idx) => {
            const result = {
                id: idx,
                sectionType: SectionType.AdditionalStop,
                address: section.address,
                coordinates: mapToCoords(section.location),
            };
            return result;
        });

export const clearRouteAndMarkers = () => {
    const route = {
        segments: [],
        markers: [],
    };

    sendMessage(widgetActions.renderRoute(route));
    renderShapes();

    store.dispatch(suggestedRoutesRemoved());
};

export const propagateBoundingBox = (dispatch: RootDispatch, getState: () => RootState) => {
    const state = getState();
    const trip = selectSuggestedRoutes(state);

    if (!trip || isEmpty(trip)) {
        return;
    }

    requestBoundingBox(getRoutesFromTrip(trip));
};
