import ExpanderPanel from '@rio-cloud/rio-uikit/ExpanderPanel';
import { FormattedMessage } from 'react-intl';
import { useRouteWidgetContext } from '../../context/RouteWidgetContext';
import {
    commonExpanderClassName,
    commonHeaderClassName,
    commonBodyClassName,
} from '../SettingsOptions/SettingsOptions';
import { RouteConstraints } from './RouteConstraints';
import { RouteCosts } from './RouteCosts';
import { RouteDeparture } from './RouteDeparture';

export const RouteOptions = () => {
    const widgetContext = useRouteWidgetContext();

    return (
        <div className={`RouteOptions ${commonHeaderClassName}`} data-testid="route-options">
            <ExpanderPanel
                bsStyle="default"
                className={commonExpanderClassName}
                bodyClassName={commonBodyClassName}
                title={<FormattedMessage id="intl-msg:smartRoute.tour.departure" />}
                onToggle={(value: boolean) => {
                    widgetContext.setDepartureDateTimeExpnd(value);
                }}
                open={widgetContext.departureDateTimeExpnd}
            >
                <RouteDeparture />
            </ExpanderPanel>
            <ExpanderPanel
                bsStyle="default"
                className={commonExpanderClassName}
                bodyClassName={commonBodyClassName}
                title={<FormattedMessage id="intl-msg:smartRoute.tour.restrictions" />}
                onToggle={(value: boolean) => {
                    widgetContext.setRestrictionsExpnd(value);
                }}
                open={widgetContext.restrictionsExpnd}
            >
                <RouteConstraints />
            </ExpanderPanel>
            <ExpanderPanel
                bsStyle="default"
                className={commonExpanderClassName}
                bodyClassName={commonBodyClassName}
                title={<FormattedMessage id="intl-msg:smartRoute.generic.costs" />}
                onToggle={(value: boolean) => {
                    widgetContext.setCostsExpnd(value);
                }}
                open={widgetContext.costsExpnd}
            >
                <RouteCosts />
            </ExpanderPanel>
        </div>
    );
};
