import SmoothScrollbars from '@rio-cloud/rio-uikit/components/smoothScrollbars/SmoothScrollbars';
import Fade from '@rio-cloud/rio-uikit/Fade';
import { Flags } from 'react-feature-flags';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { DEFAULT_ROUTE, TOUR_SETTINGS_ROUTE } from '../../common/constants';
import { PageNavigationBar } from '../../components/PageNavigationBar/PageNavigationBar';
import { useAppSelector } from '../../configuration/setup/hooks';
import { ChargingOptions } from '../../containers/ChargingOptions/ChargingOptions';
import { RouteOptions } from '../../containers/RouteOptions/RouteOptions';
import { commonSectionClassName } from '../../containers/SettingsOptions/SettingsOptions';
import { selectPageDisplayed } from '../../store/app/appSelectors';
import { updatePage } from '../../store/app/appSlice';

export const TourSettingsPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const showContent = useAppSelector(selectPageDisplayed) === TOUR_SETTINGS_ROUTE;

    const handleExitNav = () => {
        navigate(DEFAULT_ROUTE, { replace: false });
    };

    const handleClickGoBackNav = () => {
        dispatch(updatePage(DEFAULT_ROUTE));
    };

    return (
        <Fade animationStyle={Fade.PAGE_BACK} show={showContent} onExitComplete={handleExitNav}>
            <div className="display-flex flex-column height-100vh margin-15" data-testid="tour-settings-page">
                <div className="margin-left--20" data-testid="summary-back-button">
                    <PageNavigationBar
                        title="intl-msg:smartRoute.generic.tourSettings"
                        navBack={handleClickGoBackNav}
                    />
                </div>
                <SmoothScrollbars>
                    <div className="display-flex flex-column" data-testid="tour-settings">
                        <RouteOptions />
                        <Flags authorizedFlags={['chargingOptions']}>
                            <div data-testid="charging-settings">
                                <div className={commonSectionClassName}>
                                    <FormattedMessage id="intl-msg:smartRoute.generic.charging" />
                                </div>
                                <ChargingOptions />
                            </div>
                        </Flags>
                    </div>
                </SmoothScrollbars>
            </div>
        </Fade>
    );
};
