import isUndefined from 'lodash/fp/isUndefined';
import { FormattedMessage } from 'react-intl';
import { LabeledSelect } from '../../components/LabeledSelect/LabeledSelect';
import { useAppDispatch, useAppSelector } from '../../configuration/setup/hooks';
import { VehicleType2 } from '../../store/facade/facadeApi';
import { selectTrailerField, selectTruckTypeField } from '../../store/vehicleProfile/vehicleProfileSelectors';
import { changeTrailer, changeTruckType } from '../../store/vehicleProfile/vehicleProfileSlice';

export const truckTypes = [
    {
        id: VehicleType2.StraightTruck,
        label: <FormattedMessage id="intl-msg:smartRoute.vehicle.truckType.chassis" />,
    },
    {
        id: VehicleType2.Tractor,
        label: <FormattedMessage id="intl-msg:smartRoute.vehicle.truckType.tractor" />,
    },
];

export enum TrailerOption {
    YES = 'YES',
    NO = 'NO',
}

export const trailerOptions = [
    {
        id: TrailerOption.YES,
        label: <FormattedMessage id="intl-msg:smartRoute.generic.yes" />,
    },
    {
        id: TrailerOption.NO,
        label: <FormattedMessage id="intl-msg:smartRoute.generic.no" />,
    },
];

export const TruckTypeSelect = () => {
    const dispatch = useAppDispatch();
    const selectedType = useAppSelector(selectTruckTypeField);
    const { value: selectedTrailer, isEditable: isTrailerEditable } = useAppSelector(selectTrailerField);

    const handleTruckTypeChange = (selectedOption: string) => {
        const selectedTruckType = selectedOption as VehicleType2;
        dispatch(changeTruckType(selectedTruckType));
    };

    const handleTrailerChange = () => {
        dispatch(changeTrailer(!selectedTrailer));
    };

    const getOption = () => {
        if (isUndefined(selectedTrailer)) {
            return undefined;
        } else {
            return selectedTrailer ? TrailerOption.YES : TrailerOption.NO;
        }
    };

    return (
        <div className="display-flex justify-content-between gap-10" data-testid="truck-type-options">
            <LabeledSelect
                className="width-60pct"
                label={<FormattedMessage id="intl-msg:smartRoute.vehicle.vehicleType" />}
                optionLabels={truckTypes}
                selectedOption={selectedType.value}
                onChange={handleTruckTypeChange}
                disabled={!selectedType.isEditable}
            />
            <LabeledSelect
                className="flex-1-1-0"
                label={<FormattedMessage id="intl-msg:smartRoute.vehicle.trailer" />}
                optionLabels={trailerOptions}
                selectedOption={getOption()}
                onChange={handleTrailerChange}
                disabled={!isTrailerEditable}
            />
        </div>
    );
};
