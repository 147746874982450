import Button from '@rio-cloud/rio-uikit/Button';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { TOUR_SETTINGS_ROUTE, VEHICLE_ROUTE } from '../../common/constants';
import { useAppDispatch, useAppSelector } from '../../configuration/setup/hooks';
import { SearchEditor } from '../../features/search/SearchEditor';
import { updatePage } from '../../store/app/appSlice';
import { useGetVehicleProfileListQuery } from '../../store/facade/facadeApi';
import { selectSuggestedRoutes } from '../../store/route/routeSelectors';
import { TourAlternatives } from '../TourAlternatives/TourAlternatives';
import { VehicleSelection } from '../VehicleOptions/VehicleSelection';
import { VehicleTypeSwitch } from '../VehicleOptions/VehicleTypeSwitch';

export const commonSectionClassName = classNames('text-size-18 text-medium padding-top-15 padding-bottom-5');
export const commonExpanderClassName = classNames('');
export const commonHeaderClassName = classNames('display-flex flex-column gap-10');
export const commonBodyClassName = classNames('rounded-bottom bg-lightest padding-bottom-5');

interface OptionsButtonProps {
    routerOption: string;
}

const OptionsButton = ({ routerOption }: OptionsButtonProps) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const handleBtnClick = () => {
        navigate(routerOption, { replace: false });
        dispatch(updatePage(routerOption));
    };

    return (
        <Button
            data-testid="stopovers-options-btn"
            bsSize={Button.SM}
            bsStyle={Button.DEFAULT}
            className="padding-5 margin-left-10"
            onClick={handleBtnClick}
        >
            <span className="rioglyph rioglyph-cog margin-0" data-testid="srp-opt-icon" />
        </Button>
    );
};

export const SettingsOptions = () => {
    const trip = useAppSelector(selectSuggestedRoutes);

    useGetVehicleProfileListQuery();

    return (
        <div className="SettingsOptions margin-bottom-15" data-testid="settings-options">
            <div data-testid="vehicle-settings">
                <div className={commonSectionClassName}>
                    <div className="display-flex align-items-center justify-content-between">
                        <FormattedMessage id="intl-msg:smartRoute.generic.vehicle" />
                        <div className="display-flex">
                            <VehicleTypeSwitch />
                            <OptionsButton routerOption={VEHICLE_ROUTE} />
                        </div>
                    </div>
                </div>
                <VehicleSelection />
            </div>

            {trip && (
                <div className="margin-top-15">
                    <div className={commonSectionClassName}>
                        <FormattedMessage id="intl-msg:smartRoute.tour.alternatives.title" />
                    </div>
                    <TourAlternatives routes={trip.routes} />
                </div>
            )}

            <div data-testid="route-settings" className="margin-top-20">
                <div className={commonSectionClassName}>
                    <div className="display-flex align-items-center justify-content-between">
                        <FormattedMessage id="intl-msg:smartRoute.tour.stopovers.title" />
                        <OptionsButton routerOption={TOUR_SETTINGS_ROUTE} />
                    </div>
                </div>
                <div data-testid="search-editor">
                    <SearchEditor />
                </div>
            </div>
        </div>
    );
};
