import Select, { SelectOption } from '@rio-cloud/rio-uikit/Select';
import useLocalStorage from '@rio-cloud/rio-uikit/useLocalStorage';
import getSymbolFromCurrency from 'currency-symbol-map';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { supportedCurrencies } from '../../common/supportedCurrencies';
import { LabeledNumberInput } from '../../components/LabeledNumberInput/LabeledNumberInput';
import { useAppDispatch, useAppSelector } from '../../configuration/setup/hooks';
import { selectEnergyCosts, selectVehicleCosts } from '../../store/route/routeSelectors';
import { updateEnergyCosts, updateVehicleCosts } from '../../store/route/routeSlice';
import { selectIsEV } from '../../store/vehicleProfile/vehicleProfileSelectors';

export const RouteCosts = () => {
    const dispatch = useAppDispatch();

    const isEV = useAppSelector(selectIsEV).value;
    const vehicleCosts = useAppSelector(selectVehicleCosts).value;
    const energyCosts = useAppSelector(selectEnergyCosts).value;
    const currencyCosts = useAppSelector(selectVehicleCosts).currency;

    const [storedCostPerKm, setStoredCostPerKm] = useLocalStorage('costPerKm', vehicleCosts);
    const [storedCostPerKWh, setStoredCostPerKWh] = useLocalStorage('costPerKWh', energyCosts);
    const [storedCurrency, setStoredCurrency] = useLocalStorage('currency', currencyCosts);

    const [vehicleCostValue, setVehicleCostValue] = useState<number>(storedCostPerKm ?? vehicleCosts);
    const [energyCostValue, setEnergyCostValue] = useState<number>(storedCostPerKWh ?? energyCosts);
    const [currency, setCurrency] = useState<string>(storedCurrency ?? currencyCosts);

    const currencyOptionLabels: SelectOption[] = supportedCurrencies.map((it) => ({
        id: it,
        label: `${it} / ${getSymbolFromCurrency(it)}`,
        selected: true,
    }));

    const handleVehicleCostValueChange = (value: number) => {
        setVehicleCostValue(value);
    };

    const handleEnergyCostValueChange = (value: number) => {
        setEnergyCostValue(value);
    };

    const handleCurrencyChange = (selectedOption: SelectOption) => {
        setCurrency(selectedOption.id);
    };

    const renderCurrencyField = (
        <Select pullRight options={currencyOptionLabels} value={[currency]} onChange={handleCurrencyChange} />
    );

    useEffect(() => {
        setStoredCostPerKm(vehicleCostValue);
    }, [vehicleCosts]);

    useEffect(() => {
        setStoredCostPerKWh(energyCostValue);
    }, [energyCosts]);

    useEffect(() => {
        setStoredCurrency(currency);
    }, [currency]);

    useEffect(() => {
        dispatch(updateVehicleCosts({ value: vehicleCostValue, currency }));
        dispatch(updateEnergyCosts({ value: energyCostValue, currency }));
    }, [vehicleCostValue, energyCostValue, currency]);

    return (
        <>
            <div className="display-flex align-items-end" data-testid="vehicle-costs-inputs">
                <div className="flex-1-1-0 form-group margin-bottom-10">
                    <label>
                        <FormattedMessage id="intl-msg:smartRoute.vehicle.vehicleCostsPerKm" />
                    </label>
                    <div className="display-flex input-group" data-testid="vehicle-costs-value">
                        <div className="input-group-box" style={{ flex: '85%' }}>
                            <LabeledNumberInput
                                id="input-height"
                                className="flex-1-1-0 margin-right--1 margin-bottom-0"
                                value={vehicleCostValue}
                                onChange={handleVehicleCostValueChange}
                            />
                        </div>
                        <div className="input-group-btn" style={{ flex: '15%' }} data-testid="vehicle-costs-currency">
                            {renderCurrencyField}
                        </div>
                    </div>
                </div>
            </div>
            {isEV && (
                <div className="display-flex align-items-end" data-testid="energy-costs-inputs">
                    <div className="flex-1-1-0 form-group margin-bottom-10">
                        <label>
                            <FormattedMessage id="intl-msg:smartRoute.vehicle.energyCostsPerKWh" />
                        </label>
                        <div className="display-flex input-group" data-testid="energy-costs-value">
                            <div className="input-group-box" style={{ flex: '85%' }}>
                                <LabeledNumberInput
                                    id="input-height"
                                    className="flex-1-1-0 margin-right--1 margin-bottom-0"
                                    value={energyCostValue}
                                    onChange={handleEnergyCostValueChange}
                                />
                            </div>
                            <div
                                className="input-group-btn"
                                style={{ flex: '15%' }}
                                data-testid="energy-costs-currency"
                            >
                                {renderCurrencyField}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};
