import OverlayTrigger from '@rio-cloud/rio-uikit/OverlayTrigger';
import Tooltip from '@rio-cloud/rio-uikit/Tooltip';
import isUndefined from 'lodash/fp/isUndefined';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useAppDispatch, useAppSelector } from '../../configuration/setup/hooks';
import {
    selectIsEV,
    selectIsSelectedVehicleEv,
    selectVehicleProfile,
} from '../../store/vehicleProfile/vehicleProfileSelectors';
import { changeIsEV, changeIsProfileSubmited } from '../../store/vehicleProfile/vehicleProfileSlice';

export const VehicleTypeSwitch = () => {
    const dispatch = useAppDispatch();
    const isEV = useAppSelector(selectIsEV).value;
    const isVehicleLocked = useAppSelector(selectVehicleProfile).assetLocked;
    const isSelectedVehicleEv = useAppSelector(selectIsSelectedVehicleEv);

    const [isDisabled, setIsDisabled] = useState<boolean>(false);

    useEffect(() => {
        setIsDisabled(!isUndefined(isVehicleLocked) && !isUndefined(isSelectedVehicleEv));
    }, [isVehicleLocked, isSelectedVehicleEv]);

    const getBtnBackground = (flag: boolean) => {
        return `${flag ? 'active' : ''}`;
    };

    const handleVehicleTypeToggle = () => {
        dispatch(changeIsEV(!isEV));
        dispatch(changeIsProfileSubmited(false));
    };

    const btnCommonClass = 'btn btn-sm btn-component btn-icon-only btn-default';

    return (
        <div className="btn-group display-flex" data-testid="vehicle-type-switch">
            <OverlayTrigger
                placement="top"
                overlay={
                    <Tooltip id="combustion" allowOnTouch>
                        <FormattedMessage id="intl-msg:smartRoute.generic.combustionVehicle" />
                    </Tooltip>
                }
            >
                <button
                    id="combustion-btn"
                    type="button"
                    tabIndex={0}
                    style={{ flex: '50%' }}
                    className={`${btnCommonClass} ${getBtnBackground(!isEV)}`}
                    onClick={() => handleVehicleTypeToggle()}
                    disabled={isDisabled}
                >
                    <span className="rioglyph-icon-pair margin-right-5 margin-left-3 margin-right-3">
                        <span className="rioglyph rioglyph-truck text-size-16" />
                        <span className="rioglyph rioglyph-fuel-diesel scale-140" />
                    </span>
                </button>
            </OverlayTrigger>
            <OverlayTrigger
                placement="top"
                overlay={
                    <Tooltip id="electric" allowOnTouch>
                        <FormattedMessage id="intl-msg:smartRoute.generic.electricVehicle" />
                    </Tooltip>
                }
            >
                <button
                    id="electric-btn"
                    type="button"
                    tabIndex={0}
                    style={{ flex: '50%' }}
                    className={`${btnCommonClass} ${getBtnBackground(isEV!)}`}
                    onClick={() => handleVehicleTypeToggle()}
                    disabled={isDisabled}
                >
                    <span className="rioglyph-icon-pair margin-right-5 margin-left-3 margin-right-3">
                        <span className="rioglyph rioglyph-truck text-size-16" />
                        <span className="rioglyph rioglyph-fuel-electric scale-140" />
                    </span>
                </button>
            </OverlayTrigger>
        </div>
    );
};
