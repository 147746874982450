import { useSortable } from '@dnd-kit/sortable';
import { CSS as cssDndKit } from '@dnd-kit/utilities';
import Button from '@rio-cloud/rio-uikit/Button';
import FadeExpander from '@rio-cloud/rio-uikit/FadeExpander';
import getOr from 'lodash/fp/getOr';
import isNil from 'lodash/fp/isNil';
import isUndefined from 'lodash/fp/isUndefined';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useAppDispatch, useAppSelector } from '../../configuration/setup/hooks';
import { useGetAssetDataQuery } from '../../store/facade/facadeApi';
import { selectSuggestedAddresses, selectWaypointsOrder } from '../../store/search/searchSelectors';
import {
    type Waypoint,
    WaypointType,
    suggestedAddressesChanged,
    updateExtendedWaypoint,
    waypointAdded,
    waypointChanged,
    waypointRemoved,
} from '../../store/search/searchSlice';
import { selectVehicleProfile } from '../../store/vehicleProfile/vehicleProfileSelectors';
import { debounceFetchAddressesHandler, fetchAddresses } from '../fetchData/fetchAddresses';
import { AddWaypointButton } from './AddWaypointButton';
import { ExtendedOptions } from './ExtendedOptions';
import { WaypointInput } from './WaypointInput';

interface Suggestion {
    label: string;
    position: Coordinates;
    resultType: string;
    title: string;
    type: WaypointType;
    station_id: string;
}

interface Coordinates {
    lat: number;
    lng: number;
}

type WaypointListItemProps = {
    value: Waypoint;
    orderIndex: number;
    allowRemove: boolean;
    totalWaypoints: number;
};

export const getIcon = (index: number, max: number, waypoint: Waypoint) => {
    if (index === 0) {
        return 'start';
    } else if (index === max) {
        return 'finish';
    } else if (waypoint.type === WaypointType.CHARGING_STATION) {
        return 'filling-e-station';
    } else if (waypoint.type === WaypointType.ADDITIONAL_STOP) {
        return 'arrow-down';
    }
    return 'arrow-down';
};

export const mapSuggestionType = (suggestionType: string) => {
    if (suggestionType === 'charging-station') {
        return WaypointType.CHARGING_STATION;
    }
    if (suggestionType === 'vehicle-location') {
        return WaypointType.VEHICLE_LOCATION;
    }
    return WaypointType.ADDITIONAL_STOP;
};

export const WaypointListItem = (props: WaypointListItemProps) => {
    const { value: waypoint, orderIndex, allowRemove, totalWaypoints } = props;
    const dispatch = useAppDispatch();
    const intl = useIntl();

    const extended = waypoint.extended;
    const suggestedAddresses = useAppSelector(selectSuggestedAddresses);
    const waypointsOrder = useAppSelector(selectWaypointsOrder);
    const assetLocked = useAppSelector(selectVehicleProfile).assetLocked;
    const assetLockedId = assetLocked?.asset_id;

    const { currentData: vehicleData } = useGetAssetDataQuery(
        { assetId: assetLockedId! },
        { skip: isNil(assetLockedId) }
    );

    const vehiclePosition = {
        lat: vehicleData?.asset_data?.position?.latitude,
        lng: vehicleData?.asset_data?.position?.longitude,
    };

    const waypointId = waypoint.id;
    const isFirstWaypoint = waypointId === waypointsOrder[0];
    const addresses = getOr([], waypointId, suggestedAddresses);
    const isIntermediateWaypoint = waypointId !== waypointsOrder.at(0) && waypointId !== waypointsOrder.at(-1);

    useEffect(() => {
        // Reset state of first waypoint input and update suggestions when asset changes
        const id = waypointsOrder[0];
        let position;
        if (assetLockedId && !isUndefined(vehiclePosition.lat) && !isUndefined(vehiclePosition.lng)) {
            position = vehiclePosition;
        }
        if (waypoint.type === WaypointType.VEHICLE_LOCATION) {
            if (isFirstWaypoint) {
                // Clear 'use Vehicle Location' from WaypointInput if new selected vehicle has no position
                if (isUndefined(position)) {
                    dispatch(
                        waypointChanged({
                            id,
                            address: '',
                            type: WaypointType.ADDITIONAL_STOP,
                        } as Waypoint)
                    );
                    dispatch(
                        suggestedAddressesChanged({
                            addresses: [],
                            id,
                        })
                    );
                } else {
                    // Update first waypoint coordinates based on new selected vehicle position, if in usage
                    dispatch(
                        waypointChanged({
                            ...waypoint,
                            coordinates: position,
                        } as Waypoint)
                    );
                    // Update suggestions based on new selected vehicle
                    dispatch(fetchAddresses(id, waypoint.address ?? '', isFirstWaypoint, position, intl));
                }
            }
        }
    }, [vehicleData, assetLockedId]);

    const handleInputValueChanged = (id: number, address: string, type: WaypointType) => {
        handleDeleteSuggestedRoutes(id);
        dispatch(
            waypointChanged({
                id,
                address,
                type: type ?? WaypointType.ADDITIONAL_STOP,
            } as Waypoint)
        );

        let position;
        if (assetLockedId && !isUndefined(vehiclePosition.lat) && !isUndefined(vehiclePosition.lng)) {
            position = vehiclePosition;
        }

        dispatch(debounceFetchAddressesHandler(id, address, isFirstWaypoint, position, intl));
    };

    const handleDeleteSuggestedRoutes = (id: number) => dispatch(suggestedAddressesChanged({ id, addresses: [] }));

    const handleSuggestionSelected = async (id: number, suggestion: Suggestion) => {
        handleDeleteSuggestedRoutes(id);
        const newWaypoint = {
            id,
            address: suggestion.label,
            coordinates: { ...suggestion.position },
            type: mapSuggestionType(suggestion.resultType),
            station_id: suggestion.station_id,
            isInRange: null,
        } as Waypoint;

        dispatch(waypointChanged(newWaypoint));
    };

    const handleInputCleared = (id: number) => handleDeleteSuggestedRoutes(id);

    const handleWaypointAdd = () => dispatch(waypointAdded(waypointId));

    const handleWaypointRemove = () => dispatch(waypointRemoved(waypointId));

    const { setNodeRef, transform, transition, listeners } = useSortable({ id: waypointId });
    const style = {
        transform: cssDndKit.Transform.toString(transform),
        transition,
    };

    return (
        <>
            <div
                ref={setNodeRef}
                style={style}
                {...listeners}
                className="bg-lightest rounded padding-10 display-flex align-items-baseline"
                data-testid="waypoint-container"
            >
                <DragHandle />
                <div className="flex-1-1-0">
                    <WaypointInput
                        waypoint={waypoint}
                        suggestedAddresses={addresses}
                        onInputValueChanged={handleInputValueChanged}
                        onSuggestionSelected={handleSuggestionSelected}
                        onInputCleared={handleInputCleared}
                        onRemove={handleWaypointRemove}
                        allowRemove={allowRemove}
                        icon={getIcon(orderIndex, totalWaypoints, waypoint)}
                    />

                    <FadeExpander show={extended}>
                        <ExtendedOptions waypoint={waypoint} />
                    </FadeExpander>
                </div>
                {isIntermediateWaypoint && (
                    <div className="align-self-center">
                        <Button
                            bsStyle="muted"
                            bsSize="sm"
                            style={{ paddingRight: 1 }}
                            onClick={() => dispatch(updateExtendedWaypoint({ waypointId, extended: !extended }))}
                        >
                            <span
                                className={`margin-0 rioglyph ${
                                    extended ? 'rioglyph-angle-double-up' : 'rioglyph-angle-double-down'
                                }`}
                            />
                        </Button>
                    </div>
                )}
            </div>
            <AddWaypointButton onClick={handleWaypointAdd} />
        </>
    );
};

const DragHandle = () => {
    return (
        <div
            className="btn btn-muted btn-sm btn-icon-only cursor-grab padding-left-5
        margin-left--10 width-25 align-self-center"
        >
            <span className="rioglyph rioglyph-drag-n-drop" />
        </div>
    );
};
