import Button from '@rio-cloud/rio-uikit/Button';
import SmoothScrollbars from '@rio-cloud/rio-uikit/components/smoothScrollbars/SmoothScrollbars';
import Fade from '@rio-cloud/rio-uikit/Fade';
import first from 'lodash/fp/first';
import isUndefined from 'lodash/fp/isUndefined';
import last from 'lodash/fp/last';
import { lazy } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { DEFAULT_ROUTE, SUMMARY_ROUTE } from '../../common/constants';
import { coordsToCoordinates } from '../../common/utils';
import { PageNavigationBar } from '../../components/PageNavigationBar/PageNavigationBar';
import { useAppDispatch, useAppSelector } from '../../configuration/setup/hooks';
import { SectionsContainer } from '../../containers/SectionsContainer/SectionsContainer';
import { TourSummary } from '../../containers/TourSummary/TourSummary';
import { VehicleSelection } from '../../containers/VehicleOptions/VehicleSelection';
import { selectPageDisplayed } from '../../store/app/appSelectors';
import { updatePage } from '../../store/app/appSlice';
import { selectSelectedRoute, selectSuggestedRoutes } from '../../store/route/routeSelectors';
import { selectOrderedWaypoints } from '../../store/search/searchSelectors';
import { selectVehicleProfile } from '../../store/vehicleProfile/vehicleProfileSelectors';
import { showMapNotification } from '../../store/widget/actions/showMapNotification';

const DownloadPdfButton = lazy(() =>
    import('../../components/DownloadPDF/DownloadPDF').then((module) => ({ default: module.DownloadPDF }))
);

export const RouteSummaryPage = () => {
    const intl = useIntl();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const showContent = useAppSelector(selectPageDisplayed) === SUMMARY_ROUTE;
    const assetLocked = useAppSelector(selectVehicleProfile).assetLocked;
    const trip = useAppSelector(selectSuggestedRoutes);
    const selectedRoute = useAppSelector(selectSelectedRoute);
    const selectedRouteData = trip?.routes[selectedRoute!];
    const waypoints = useAppSelector(selectOrderedWaypoints);
    const departureWaypoint = first(waypoints);
    const arrivalWaypoint = last(waypoints);

    const handleExitNav = () => {
        navigate(DEFAULT_ROUTE, { replace: false });
    };

    const handleClickGoBackNav = () => {
        dispatch(updatePage(DEFAULT_ROUTE));
    };

    const sendToVehicleHandler = async () => {
        if (isUndefined(departureWaypoint?.coordinates) || isUndefined(arrivalWaypoint?.coordinates)) {
            return;
        }
        try {
            const response = await fetch('https://2d42flfxs4.execute-api.eu-west-1.amazonaws.com/prod/create', {
                method: 'POST',
                body: JSON.stringify({
                    origin: coordsToCoordinates(departureWaypoint?.coordinates),
                    destination: coordsToCoordinates(arrivalWaypoint?.coordinates),
                    device_id: '1ebff057-3699-4eb1-a47b-5e21054493cf',
                }),
            });

            if (response.ok) {
                showMapNotification(
                    'success',
                    intl.formatMessage({ id: 'intl.msg:smartRoute.sendTourToVehicle.successMessage' }),
                    intl.formatMessage({ id: 'intl.msg:smartRoute.toast.title' })
                );
            } else {
                throw new Error();
            }
        } catch (error) {
            showMapNotification(
                'error',
                intl.formatMessage({ id: 'intl.msg:smartRoute.sendTourToVehicle.errorMessage' }),
                intl.formatMessage({ id: 'intl.msg:smartRoute.toast.title' })
            );
        }
    };

    return (
        <Fade animationStyle={Fade.PAGE_BACK} show={showContent} onExitComplete={handleExitNav}>
            <div className="display-flex flex-column height-100vh" data-testid="route-summary-page">
                <div className="display-flex align-items-baseline margin-x--5" data-testid="summary-back-button">
                    <PageNavigationBar title="intl-msg:smartRoute.summary.title" navBack={handleClickGoBackNav} />
                </div>
                <SmoothScrollbars>
                    <div className="display-flex flex-column padding-15" data-testid="route-summary-layout">
                        {trip && (
                            <div className="display-flex flex-column gap-25" data-testid="route-summary-tabs">
                                <div data-testid="vehicle-settings">
                                    <div className="text-size-18 text-medium padding-top-15 padding-bottom-5">
                                        <div className="display-flex align-items-center justify-content-between">
                                            <FormattedMessage id="intl-msg:smartRoute.generic.vehicle" />
                                        </div>
                                    </div>
                                    <VehicleSelection />
                                </div>

                                <TourSummary trip={trip} selectedRoute={selectedRoute!} />

                                {selectedRouteData && <SectionsContainer route={selectedRouteData} />}
                            </div>
                        )}
                    </div>
                </SmoothScrollbars>
                <div
                    className="display-flex flex-1 justify-content-between gap-15 margin-15 padding-bottom-15"
                    data-testid="route-summary-btns"
                >
                    <DownloadPdfButton />
                    <div>
                        <Button onClick={sendToVehicleHandler} bsStyle="primary" disabled={isUndefined(assetLocked)}>
                            <span className="rioglyph rioglyph-send" aria-hidden="true" />
                            <FormattedMessage id="intl.msg:smartRoute.pdf.sendButton" />
                        </Button>
                    </div>
                </div>
            </div>
        </Fade>
    );
};
