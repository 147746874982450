import { useIntl } from 'react-intl';

interface TourSummaryPanelFooterProps {
    footerTimestamp?: string;
}

export const TourSummaryPanelFooter = ({ footerTimestamp }: TourSummaryPanelFooterProps) => {
    const intl = useIntl();
    const label = intl.formatMessage({ id: 'intl-msg:smartRoute.generic.lastUpdatedAt' });
    return (
        <div className="display-flex column-gap-10 justify-content-between" data-testid="tour-bottom">
            <div>
                <span data-testid="tour-last-update">{`${label}: `}</span>
                <span className="text-medium">{footerTimestamp}</span>
            </div>
        </div>
    );
};
